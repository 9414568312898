<template>
  <div class="card mt-2 add-recipe" v-if="!recipePreviewIsVisible">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h2 class="card-title">Add Steps</h2>
      <button class="btn btn-secondary" @click="previousStep" v-if="currentStep > 1 && !createSingleStep">Back</button>
      <button class="btn btn-secondary" @click="showRecipePreview" v-if="createSingleStep">Back</button>
    </div>
    <div class="card-body">
      <div class="container-fluid">
        <div class="step-title mb-4">
          <h5>Step Number: {{ currentStep }}</h5>
        </div>
        <div class="row">
          <div class="col-xl-9 col-lg-7 details-field">

            <form class="w-100" style="display: flex" enctype="multipart/form-data">
              <div class="col-12">
                <div class="row">
                  <div class="col-5">
                    <div class="form-group">
                      <label for="name">Title of the Step</label>
                      <input
                        name="title"
                        type="text"
                        class="form-control"
                        v-model="step.title"
                        placeholder="Enter title"
                        v-invalid="form.$errors"
                      />
                      <field-error :bag="form.$errors" field="title"/>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for="name">Step Timer</label>
                      <select
                        name="timer"
                        class="form-control mb-2 mb-sm-0 mr-sm-2"
                        v-model="step.timer"
                        v-invalid="form.$errors"
                      >
                        <option value="" disabled selected>
                          Select Timer
                        </option>
                        <option v-for="(timer) in stepTimers" :value="timer.value">{{ timer.message }}</option>
                      </select>
                      <field-error :bag="form.$errors" field="timer"/>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label for="name">Timer in Minutes</label>
                      <input
                        type="number"
                        name="timer_in_minutes"
                        class="form-control mb-2 mb-sm-0 mr-sm-2"
                        v-model="step.timer_in_minutes"
                        :disabled="parseInt(step.timer) === 0"
                        v-invalid="form.$errors"
                        min="1"
                      >
                      <field-error :bag="form.$errors" field="timer_in_minutes"/>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <label for="name">Instruction</label>
                      <textarea
                        name="instruction"
                        class="form-control"
                        v-model="step.instruction"
                        placeholder="Add Step Instruction"
                        rows="14"
                        v-invalid="form.$errors"
                      />
                      <field-error :bag="form.$errors" field="instruction"/>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="col-xl-3 col-lg-5 image-field">
            <div>
              <div class="form-group img-wrapper my-4 mx-auto" id="img-wrapper">
                <img width="386px;"
                     v-if="imagePreview"
                     :src="imagePreview"
                     alt="recipe_image" >
              </div>
              <label v-if="Object.keys(form.$errors.errors).length > 0 && form.$errors.errors.image !== undefined" class="text-danger invalid-error">{{ form.$errors.errors.image[0] }}</label>
              <div class="form-group">
                <button
                  class="btn btn-outline-primary btn-block select-btn-custom"
                  @click="$refs.file.click()"
                >
                  Select an image of the Recipe
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 add-ingredient-wrapper">
              <h5>Add Ingredients</h5>
              <div>
                <label for="" class="form-label">Select By : </label>
                <label class="ml-2">
                  Name <input type="radio" value="name" v-model="catalogSelectBy">
                </label>

                <label class="ml-2">
                  SKU <input type="radio" value="sku" v-model="catalogSelectBy">
                </label>
                <div v-if="catalogSelectBy === 'sku'" class="row">
                  <input type="text"
                         class="form-control col-3"
                         placeholder="Search by SKU"
                         v-model="skuValue">
                  <button type="button"
                          @click="searchBySku"
                          class="btn btn-primary ml-2">
                    <i class="fa fa-search fa-fw"/>
                  </button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-xl-1">
                  <div class="row">
                    <div class="col-2">
                      <div class="form-group">
                        <label>Image</label>
                        <img :src="ingredient.catalog ? ingredient.catalog.image : '/img/no-image.png'" class="ingredient-image" alt="ingredient-image">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label>Select an Item</label>
<!--                        <select class="form-control" v-model="ingredient.catalog_id">-->
<!--                          <option v-for="(item, index) in stepItems" :key="index" :value="item.id">{{ item.name }}</option>-->
<!--                        </select>-->
                        <multiselect
                          id="catalog_id"
                          name="catalog_id"
                          label="nameWithCategory"
                          track-by="id"
                          @input="changeSelectBy"
                          v-model="ingredient.catalog"
                          :options="stepItemsWithCategory"
                          :taggable="false"
                          :searchable="true"
                          :close-on-select="true"
                          placeholder="Select Ingredient"
                          @remove="unselectIngredient"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Unit Of Measure</label>
                        <select class="form-control" v-model="ingredient.variation_data_id">
                          <option v-for="(unit_of_measure) of recipeUnitOfMeasures" :value="unit_of_measure.id">{{ unit_of_measure.value }}</option>
                        </select>
                        <div class="dynamic-label w-75 mt-3" v-if="ingredient.variation_data_id">
                          <label>{{ 'Sku:' + recipeYieldSku }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-5">
                  <div class="row">
                    <div class="col-3">
                      <div class="form-group">
                        <label>Qty</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model="ingredient.quantity"
                          min="1"
                        >
                        <label class="text-danger" v-if="showErrors">Qty must be greater than 1</label>
                        <div class="dynamic-label mt-3" v-if="ingredient.variation_data_id && ingredient.quantity">
                          <label>{{ 'Price: $ ' + itemPrice }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-5 text-right">
                      <div class="form-group">
                        <button class="btn btn-primary mt-4 ml-2" @click="addIngredient()" :disabled="ingredient.variation_data_id === '' || ingredient.variation_data_id === undefined || ingredient.quantity === ''">
                          Add Ingredient
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3" v-if="step.ingredients.length > 0">
            <div class="col-12 my-5 ingredient-table-wrapper">
              <div class="table table-bordered ingredient-table">
                <!-- <thead> -->
                  <div class="d-flex thead">
                    <div scope="col" class="th">Image</div>
                    <div scope="col" class="th">Name</div>
                    <div scope="col" class="th">UOM</div>
                    <div scope="col" class="th">Qty</div>
                    <div scope="col" class="th">Action</div>
                  </div>
                <!-- </thead> -->
                <draggable v-model="recipe.recipe_yields" tag="div">
                  <div v-for="(multipleYield, index) in recipe.recipe_yields" :key="index" >
                    <div class="d-flex yield-list">
                      <td class="td"><img :src="multipleYield.catalog.image" class="ingredient-image" alt=""></td>
                      <td class="td">{{multipleYield.catalog.name}}</td>
                      <td class="td"> {{ getUOMByIngredient(multipleYield.catalog.id,multipleYield.variation_data_id).item_variation_data.name }}</td>
                      <td class="td">{{multipleYield.quantity}}</td>
                      <td class="td"></td>
                    </div>

                    <div class="ingredient-list" v-for="(stepIngredient, index) in step.ingredients" :key="index">
                      <div class="d-flex" v-if="stepIngredient.recipe_yield_id==multipleYield.id">
                        <td class="td">
                          <img :src="stepIngredient.catalog.image" class="ingredient-image" alt="">
                        </td>
                        <td class="td">{{ items.find((item) => item.id === stepIngredient.catalog_id).name }}</td>
                        <td class="td">
                          {{
                            getUOMByIngredient(stepIngredient.catalog_id, stepIngredient.variation_data_id).item_variation_data.name
                          }}
                        </td>
                        <td class="td">{{ stepIngredient.quantity }}</td>
                        <td class="td">
                          <div class="btn-group btn-group-sm">
                            <a title="Edit" class="btn btn-primary" @click="showEditIngredientModal(index)"><i class="fas fa-edit"></i></a>
                            <a title="Delete" href="#" class="btn btn-danger ml-1" @click="deleteIngredient(stepIngredient)"><i class="fas fa-trash"></i></a>
                          </div>
                        </td>
                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3 add-ingredient-wrapper mt-3" v-else>
            <h5 class="text-center mx-auto text-muted">No ingredients have been added for this step yet.</h5>
          </div>
          <div class="d-flex justify-content-between p-4">
            <div v-if="!createSingleStep" class="mt-3">
              <button type="button" class="btn btn-outline-danger mr-2" @click="cancelRecipe">Cancel Recipe</button>
            </div>
            <div v-if="!createSingleStep" class="btn-group mt-3" role="group" aria-label="Basic example">
              <button type="button" class="btn btn-dark ml-2" @click="showRecipePreview">Preview</button>
              <button type="button" class="btn btn-outline-primary" @click="addStep" :disabled="processing">Add Another Recipe Step</button>
              <button type="button" class="btn btn-primary" @click="saveRecipe" :disabled="(step.title === '' && step.timer === '') || processing">Save & Finish</button>
            </div>
            <div v-if="createSingleStep" class="mt-3 ml-auto">
              <button type="button"
                      class="btn btn-primary"
                      @click="saveRecipe"
                      :disabled="(step.title === '' && step.timer === '') || processing">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <step-ingredient-edit-modal v-if="ingredientEditModalIsVisible"
                                :initial="step"
                                :ingredient-number="ingredientId"
                                :ingredients="initialStepItems"
                                :step-ingredients="step.ingredients"
                                @ingredientUpdated="updateIngredient"
                                @closeModal="closeModal"
    />

    <recipe-approve-modal v-if="showRecipeApproveModal"
                          :show-modal="showRecipeApproveModal"
                          :show-cancel-button="false"
                          @closeModal="closeModal"
                          :initial="recipe"/>

    <input type="file" ref="file" name="image" @change="onFileChange" hidden />
  </div>



  <recipe-preview v-else
                  :initial="recipe"
                  :recipe-yields="items"
                  :categories="categories"
                  :step-timers="stepTimers"
                  :show-recipe-edit-button="editButtonIsVisible"
                  :show-add-step-button="addStepButtonIsVisible"
                  @closeRecipePreview="closeRecipePreview"
  />
</template>

<script>

import RecipePreview from '@/js/KitchenHand/Components/Recipe/RecipePreview';
import Form from 'laravel-form-validation';
import StepIngredientEditModal from '@/js/KitchenHand/Components/Recipe/StepIngredientEditModal';
import Multiselect from 'vue-multiselect';
import RecipeApproveModal from '@/js/KitchenHand/Components/Recipe/RecipeApproveModal';
import draggable from 'vuedraggable';

export default {
  name: 'RecipeStepCreate',
  components: { RecipePreview, StepIngredientEditModal, Multiselect, RecipeApproveModal, draggable },
  props: {
    items: {
      type: Array
    },
    initial: {
      type: Object
    },
    stepTimers: {
      type: Object,
    },
    categories: {
      type: Array,
    },
    createSingleStep: {
      type: Boolean,
    }
  },
  data() {
    return {
      currentStep: 1,
      recipe: {
        steps: 0,
      },
      step: {
        id: '',
        number: 1,
        recipe_id: this.initial.id,
        title: '',
        timer: '',
        image: '',
        timer_in_minutes: '',
        instruction: '',
        ingredients: [],
      },
      ingredient: {
        step_id: '',
        name: '',
        catalog_id: null,
        unit_of_measure: '',
        variation_data_id: '',
        quantity: '',
        recipe_yield_id: ''
      },
      processing: false,
      imagePreview: null,
      recipePreviewIsVisible: false,
      form: new Form,
      ingredientEditModalIsVisible: false,
      currentStepSaved: false,
      stepId: '',
      ingredientId: 0,
      stepItems: [],
      unitOfMeasures: [],
      showErrors: false,
      recipeYieldSku: '',
      initialStepItems: [],
      ingredientUOM: '',
      showRecipeApproveModal: false,
      catalogSelectBy: 'name',
      skuValue: '',
      skuVariationDataTemp: ''
    };
  },
  methods: {
    editItem(index){
      this.showEditIngredientModal(index);
    },
    deleteItem(stepIngredient){
      this.deleteIngredient(stepIngredient);
      console.log('item deleted step create');
    },
    addIngredient() {
      if (this.ingredient.quantity <= 0 || this.ingredient.quantity > 9999.99) {
        this.$toast.error('Quantity must be greater than 0 and less than 9999.99')
        return;
      }
      if (!this.recipeYieldSku) {
        this.$toast.error('Can\'t add ingredient with the undefined sku');
        return;
      }
      this.skuValue = ''
      delete this.ingredient['created_at']
      delete this.ingredient['updated_at']
      this.ingredient.quantity = this.ingredient.quantity ? parseFloat(this.ingredient.quantity).toFixed(2) : this.ingredient.quantity;
    
      this.recipe.recipe_yields.forEach((recipeYield) => {
        let duplicateIngredient = this.step.ingredients.some(
            (ingredient) => (ingredient.catalog_id === this.ingredient.catalog_id && ingredient.recipe_yield_id === recipeYield.id)
          );
        if(!duplicateIngredient) {
          this.step.ingredients.push({
            ...this.ingredient,
            recipe_yield_id: recipeYield.id
          })
        }
      });
      this.stepItems = this.stepItems.filter((stepItem) => stepItem.id !== this.ingredient.catalog_id)
      this.ingredient = {
        step_id: this.currentStep,
        catalog_id: null,
        name: '',
        unit_of_measure: '',
        variation_data_id: '',
        quantity: '',
      }
      this.showErrors = false;
    },
    addStep: function () {
      this.skuValue=''
      this.processing = true;
      this.step.recipe_id = this.recipe.id;
      if (!this.currentStepSaved) {
        this.form.post(route('admin.kitchen.step.store'), this.step , {headers: {'Content-Type': 'multipart/form-data'}})
          .then((response) => {
            if (response.status === 200) {
              this.recipe = response.recipe;
              this.stepId = response.id;
              ++this.currentStep;
              ++this.step.number;
              this.step = {
                id: response.id,
                number: this.step.number,
                recipe_id: this.recipe.id,
                title: '',
                timer: '',
                image: '',
                timer_in_minutes: '',
                instruction: '',
                ingredients: [],
              }
              this.ingredient = {
                step_id: this.currentStep,
                name: '',
                unit_of_measure: '',
                variation_data_id: '',
                catalog_id: null,
                quantity: '',
              }
              this.imagePreview = null
            }
          })
          .finally(() => {
            this.processing = false;
            // this.stepItems = this.items.filter((ingredient) => ingredient.id !== this.recipe.catalog_id)
            this.stepItems = this.items.filter((ingredient) => {
              // Check if no catalog_id in recipe_yields matches the current ingredient.id
              return !this.recipe.recipe_yields.some((yieldItem) =>
                ingredient.id === parseInt(yieldItem.catalog_id)
              );
            });
          })
      }
      else {
        this.currentStep = this.recipe.steps.length;
        ++this.currentStep;
        ++this.step.number;
        this.currentStepSaved = false;
        this.step = {
          id: ++this.recipe.steps[this.recipe.steps.length - 1].id,
          number: this.step.number,
          recipe_id: this.recipe.id,
          title: '',
          timer: '',
          image: '',
          timer_in_minutes: '',
          instruction: '',
          ingredients: [],
        }
        this.ingredient = {
          step_id: this.currentStep,
          name: '',
          unit_of_measure: '',
          variation_data_id: '',
          quantity: '',
        }
        this.imagePreview = null
        this.stepItems = this.items.filter((ingredient) => ingredient.id !== parseInt(this.recipe.catalog_id))
        this.initialStepItems = this.stepItems
        this.processing = false
      }
    },
    showRecipePreview() {
      this.recipePreviewIsVisible = true;
    },
    closeRecipePreview() {
      if (!this.createSingleStep) {
        this.recipePreviewIsVisible = false;
        // ((this.recipe.steps !== undefined) ? (this.currentStep = this.recipe.steps.length) + 1 : '');
        this.currentStep = this.recipe.steps ? this.recipe.steps.length +1 : this.currentStep;
      } else {
        window.location.href = route('admin.kitchen.recipe.index');
      }
    },
    showEditIngredientModal(index) {
      this.ingredientId = index;
      this.ingredientEditModalIsVisible = true;
    },

    async deleteIngredient(stepIngredient) {
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this ingredient?', {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      })

      if(!confirm) {
        return;
      }
      this.step.ingredients.splice(this.step.ingredients.indexOf(stepIngredient) ,1);
      // this.stepItems = this.items.filter((ingredient) => ingredient.id !== parseInt(this.recipe.catalog_id))
      let ingredientsToRemove = this.step.ingredients.map((ingredient) => ingredient.catalog_id)
      this.stepItems = this.stepItems.filter((ingredient) => !ingredientsToRemove.includes(ingredient.id))
      this.$toast.success('Ingredient has been deleted')
      this.stepItems.push(stepIngredient.catalog)
    },

    onFileChange(e) {
      const file = e.target.files[0];
      if (file.size > 1000000) {
        alert('Image may not be greater than 1 Mb.')
      } else {
        this.step.image = file;
        this.imagePreview = URL.createObjectURL(file);
      }
    },
    closeModal() {
      this.ingredientEditModalIsVisible = false
      this.showRecipeApproveModal = false
    },
    saveRecipe() {
      this.processing = true
      this.step.recipe_id = this.recipe.id;
      if (!this.currentStepSaved) {
        this.form.post(route('admin.kitchen.step.store'), this.step , {headers: {'Content-Type': 'multipart/form-data'}})
          .then((response) => {
            if (response.status === 200) {
              // console.log('response',response)
              this.recipe = response.recipe;
              this.recipe.multipleYields = response.multipleYieldsSelected;
              if (!this.createSingleStep) {
                this.stepId = response.id;
                this.currentStepSaved = true
                this.saveAndFinish()
              } else {
                // condition when single step is being added
                this.$toast.success('Recipe Step Added successfully');
                this.showRecipePreview();
              }
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.processing = false;
          })
      }
      else{
        this.saveAndFinish()
      }
    },
    saveAndFinish() {
      this.processing = true
      this.form.put(route('admin.kitchen.save-and-finish'), {status: 2, recipe: this.recipe.id})
        .then((response) => {
          if (response.status === 200) {
            this.showRecipeApproveModal = true
            this.recipePreviewIsVisible= false
            this.$toast.success('Recipe Saved Successfully')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
        })
    },
    previousStep() {
      if (this.currentStep > 1) {
        --this.currentStep;
        this.step = this.recipe.steps[this.currentStep - 1]
        this.currentStepSaved = true;
        this.imagePreview = this.step.image
        this.form.$errors.errors = [];
      }
    },
    getUOMByIngredient(catalogId, variationDataId) {
       return (this.items.find((item) => item.id === catalogId).variations_data).find((data) => data.id === variationDataId)
    },
    async cancelRecipe() {
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this recipe?', {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      })

      if(!confirm) {
        return;
      }

      this.form.delete(route('admin.kitchen.recipe.destroy', this.recipe.id))
        .then((response) => {
          this.$toast.success('Recipe has been deleted')
          window.location.href = route('admin.kitchen.recipe.index');
        })
        .catch(error => {
          console.error(error.message)
        })
    },

    findUOM(ingredient) {
      this.unitOfMeasures = this.stepItems.find((item) => item.name === ingredient).variations_data.map(function (unitOfMeasure) {
        return unitOfMeasure.item_variation_data.name ;
      });
      this.step.ingredients[this.ingredientId].unit_of_measure = this.unitOfMeasures[0];
    },
    updateIngredient(ingredient) {
      let ingredients = this.step.ingredients.map((ingredient) => ingredient.name);

      this.step.ingredients[this.ingredientId] = ingredient
      // this.stepItems = this.items.filter((ingredient) => ingredient.id !== parseInt(this.recipe.catalog_id))
      let ingredientsToRemove = this.step.ingredients.map((ingredient) => ingredient.catalog_id)
      this.stepItems = this.stepItems.filter((ingredient) => !ingredientsToRemove.includes(ingredient.id))
      this.ingredientEditModalIsVisible = false
    },
    findSku(variationDataId) {
      if (variationDataId !== undefined) {
        this.recipeYieldSku = this.recipeUnitOfMeasures.find((item) => item.id === variationDataId).sku
      }
    },
    searchBySku() {
      if (!this.skuValue.trim()) {
        this.$toast.error('Please enter some Sku Value !');
        return;
      }
      let selectedRecipeCatalog, selectedItemVariation;
      let skuFound = this.stepItems.find((recipeYield) => {
        return Object.entries(recipeYield).find(([itemKey, itemValue]) => {
          if (itemKey === 'variations_data') {
            return Object.entries(itemValue).find(([variationDataKey, variationDataValue]) => {
              return Object.entries(variationDataValue).find(([itemVariationKey, itemVariationValue]) => {
                if (itemVariationKey === 'item_variation_data') {
                  if (itemVariationValue.hasOwnProperty('sku')) {
                    if (itemVariationValue.sku === this.skuValue) {
                      selectedRecipeCatalog = recipeYield
                      selectedItemVariation = variationDataValue.id
                      return true;
                    }
                  }
                }
              })
            })
          }
        })
      })
      if (!skuFound) {
        //   clearing the recipe yield if not found in sku
        delete this.ingredient.catalog
        this.$set(this.ingredient, 'catalog_id', null)
        this.$set(this.ingredient, 'variation_data_id', null)
        this.$set(this.ingredient, 'quantity', null)
        this.$toast.error('No Sku found !');
      } else {
        this.$set(this.ingredient, 'catalog', selectedRecipeCatalog)
        this.$set(this.ingredient, 'catalog_id', selectedRecipeCatalog.id)
        this.$set(this.ingredient, 'variation_data_id', selectedItemVariation)
        this.skuVariationDataTemp = selectedItemVariation
      }
    },
    changeSelectBy() {
      this.catalogSelectBy = 'name'
    },
    unselectIngredient() {
      this.ingredient.variation_data_id = null
      this.ingredient.catalog_id = null
      this.ingredient.quantity = null
    }
  },
  created() {
    this.ingredient.step_id = this.currentStep;
    this.recipe = this.initial;
    this.stepItems = this.items.filter((ingredient) => {
      // Check if no catalog_id in recipe_yields matches the current ingredient.id
      return !this.recipe.recipe_yields.some((yieldItem) =>
        ingredient.id === parseInt(yieldItem.catalog_id)
      );
    });
    this.initialStepItems = this.stepItems
    if (this.recipe.steps !== undefined) {
      this.currentStep = this.recipe.steps.length + 1;
    }
    if (this.createSingleStep) {
      this.step.number = this.currentStep
    }
  },
  computed: {
    itemCategory: function () {
      return this.items.find((item) => item.id === this.ingredient.catalog_id).category.category;
    },
    itemPrice: function () {
      let variationsData = this.items.find((item) => item.id === this.ingredient.catalog_id).variations_data.find((uom) => uom.id === this.ingredient.variation_data_id)
      let itemYieldPrice = (variationsData !== undefined && variationsData.item_variation_data.price_money) ? (variationsData.item_variation_data.price_money['amount']/100.00) * this.ingredient.quantity : 0
      return itemYieldPrice ? itemYieldPrice.toFixed(2) : itemYieldPrice
    },
    recipeUnitOfMeasures: function (){
      if (this.ingredient.catalog_id !== null && this.ingredient.catalog_id !== '' && 'catalog_id' in this.ingredient) {
        let unitOfMeasures = this.items.find((stepItem) => stepItem.id === this.ingredient.catalog_id).variations_data.map(function (unitOfMeasure) {
          return {
            id: unitOfMeasure.id,
            value: unitOfMeasure.item_variation_data.name + ' - ' + ((unitOfMeasure.item_variation_data.price_money) ? (unitOfMeasure.item_variation_data.price_money.amount/100.00) : 0),
            sku: unitOfMeasure.item_variation_data.sku,
          }
        });
        this.ingredient.variation_data_id = '';
        this.ingredient.quantity = '';
        return unitOfMeasures;
      }
    },
    editButtonIsVisible: function () {
      // showing step edit and recipe edit button if preview is coming from add step functionality
      return this.createSingleStep;
    },
    addStepButtonIsVisible: function () {
      // showing add step button if preview is coming from add step functionality
      return this.createSingleStep;
    },
    stepItemsWithCategory: function () {
      return this.stepItems.map(stepItem => {
        stepItem.nameWithCategory = `${stepItem.name} (${stepItem.category.category})`
        return stepItem
      })
    }
  },
  watch: {
    step: {
      handler: function (newValue) {
        (newValue.timer === 0) ? this.step.timer_in_minutes = null : ''
        if (this.currentStepSaved) {
          let ingredientsToRemove =  newValue.ingredients.map((ingredient) => ingredient.catalog_id)
          this.stepItems = this.stepItems.filter((ingredient) => !ingredientsToRemove.includes(ingredient.id))
        }
      },
      deep: true,
    },
    ingredient: {
      handler: function (newValue) {
        if (this.recipeUnitOfMeasures !== undefined && this.ingredient.variation_data_id !== null) {
          if (this.recipeUnitOfMeasures.map((uom) => uom.id).includes(this.ingredient.variation_data_id)) {
            this.findSku(this.ingredient.variation_data_id)
          }
        }
        if ('catalog' in newValue) {
          if (newValue.catalog !== null) {
            this.ingredient.catalog_id = newValue.catalog.id;
          }
        }
      },
      deep: true,
    },
    ingredientUOM: {
      handler: function (newValue) {
        this.findSku(newValue)
      }
    },
    catalogSelectBy: {
      handler: function () {
        this.skuValue = ''
      }
    },
    skuVariationDataTemp: {
      handler: function () {
        // temporary sol'n in search by sku to save the selected item variation
        if (this.catalogSelectBy === 'sku' && this.skuVariationDataTemp) {
          this.ingredient.variation_data_id = this.skuVariationDataTemp ?? ''
          this.skuVariationDataTemp = ''
        }
      }
    }
  }

};
</script>

<style scope>
.add-ingredient-wrapper {
  background: #F8FBFF;
  padding: 2rem;
}
.step-title {
  padding-left: 7.5px;
}
.scale-checkbox label {
  margin-top: 3px;
}
.ingredient-image {
  border-radius: 50% !important;
  box-sizing: border-box;
  height: 60px;
  width: 60px;
}
.ingredient-table .th {
  background: #F8F8F8;
  border: 1px solid #D9D9D9;
  padding: 15px;
}
.ingredient-table .th:nth-child(2), .ingredient-table .td:nth-child(2) {
  width: 50%;
}
.ingredient-table .th:nth-child(6), .ingredient-table .th:nth-child(3), .ingredient-table .td:nth-child(6), .ingredient-table .td:nth-child(3) {
  width: 20%;
}
.ingredient-table .th:nth-child(1), .ingredient-table .th:nth-child(4), .ingredient-table .th:nth-child(5), .ingredient-table .td:nth-child(1), .ingredient-table .td:nth-child(4), .ingredient-table .td:nth-child(5) {
  width: 10%;
}

.yield-list {
  background-color: #ededed;
}
.yield-list td {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
}
.ingredient-list .td {
  display: flex;
  align-items: center;
}

</style>
