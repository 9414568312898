import { render, staticRenderFns } from "./RecipeHistory.vue?vue&type=template&id=5ff41b18&scoped=true&"
import script from "./RecipeHistory.vue?vue&type=script&lang=js&"
export * from "./RecipeHistory.vue?vue&type=script&lang=js&"
import style0 from "./RecipeHistory.vue?vue&type=style&index=0&id=5ff41b18&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.9.8_vue-template-compiler@2.6.14/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff41b18",
  null
  
)

export default component.exports