<template>
  <div class="modal modal-center fade move-to-worklist-modal"
       :class="showModal ? 'show' : ''"
       :style="showModal ? 'padding-right: 14px; display: block;' : 'display: none'"
       id="moveToWorkListModal"
       ref="moveToWorkListModal"
       tabindex="-1"
       aria-labelledby="moveToWorkListModal"
       aria-hidden="true">
    <div class="modal-dialog modal-ml h-30 my-0">
      <div class="modal-content h-100 w-100">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h5 class="modal-title" id="exampleModalLabel" v-if="recipe.status === workListStatus.OPEN">Edit Batch Details</h5>
          <h5 class="modal-title" id="exampleModalLabel" v-if="recipe.status !== workListStatus.OPEN">View Batch Details</h5>
          <button
            type="button"
            class="close m-0 p-0 position-static"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true" class="text-dark">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="container m-3">
            <div class="row">
              <div class="col-11">
                <!-- <h6>Recipe Yield : {{ recipeData.recipe.catalog.name }}</h6>
                <div class="form-group">
                  <label for="batch_size" class="form-label">Enter Batch Size</label>
                  <input class="form-control"
                         type="text"
                         v-model="recipe.batch_size"
                         :class="{ 'is-invalid' : form.$errors.has('batch_size')}"
                         :disabled="recipe.status !== workListStatus.OPEN"
                  >
                  <span class="text-danger">{{ form.$errors.first('batch_size') }}</span>
                </div>
                <h6>Batch Yield : {{ recipeData.recipe.quantity }}</h6>
                <h6>Total Yield : {{ recipeData.recipe.quantity*recipe.batch_size }}</h6> -->
                <div class="recipe-data-wrapper">
                  <div
                    class="border rounded p-2 mb-3"
                    v-for="(workListItem, index) in recipe.work_list_batches"
                    :key="index"
                  >
                    <h6>Recipe Yield: {{ workListItem.recipe_yield.catalog.name }}</h6>
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="number"
                        v-model.number="workListItem.batch_size"
                        placeholder="Enter Batch Size"
                        :class="{ 'is-invalid': batchSizeErrors[index] }"
                      />
                      <span class="text-danger">{{ batchSizeErrors[index] }}</span>
                    </div>
                    <h6>Batch Yield: {{ workListItem.recipe_yield.quantity }}</h6>
                    <h6>Total Yield: {{ workListItem.recipe_yield.quantity * workListItem.batch_size || 0 }}</h6>
                  </div>
                </div>
                <div class="form-group">
                  <label for="" class="form-label">Select Due Date</label>
                  <input class="form-control"
                         type="date"
                         v-model="recipe.due_date"
                         :class="{ 'is-invalid' : form.$errors.has('due_date')}"
                         :disabled="recipe.status !== workListStatus.OPEN"
                  >
                  <span class="text-danger">{{ form.$errors.first('due_date') }}</span>
                </div>
                <div class="form-group">
                  <label for="" class="for-label">Enter Additional Notes</label>
                  <textarea class="form-control"
                            name="notes"
                            placeholder="Enter the Additional Notes"
                            v-model="recipe.notes"
                            rows="5"
                            :disabled="recipe.status !== workListStatus.OPEN"
                            :class="{ 'is-invalid' : form.$errors.has('notes')}"/>
                  <span class="text-danger">{{ form.$errors.first('notes') }}</span>
                </div>
                <div class="form-group">
                  <label for="" class="form-label ml-2">Open to all employees</label>
                  <label class="ml-2">
                    <input type="radio"
                           value="YES"
                           v-model="openToAllEmployeeRadio"
                           :disabled="recipe.status !== workListStatus.OPEN"> YES
                  </label>

                  <label class="ml-2">
                    <input type="radio"
                           value="NO"
                           v-model="openToAllEmployeeRadio"
                           :disabled="recipe.status !== workListStatus.OPEN"> NO
                  </label>
                </div>
                <div v-if="!recipe.open_to_all_employees" class="form-group">
                  <label for="" class="form-label">Assign to an Employee</label>
                  <select class="form-control"
                          v-model="recipe.employee_id"
                          :class="{ 'is-invalid' : form.$errors.has('employee_id')}"
                          :disabled="recipe.open_to_all_employees === 1 || (recipe.status !== workListStatus.OPEN)"
                  >
                    <option v-for="(employee, index) in employees" :key="index" :value="employee.id">{{ employee.name }}</option>
                  </select>
                  <span class="text-danger">{{ form.$errors.first('employee_id') }}</span>
                </div>

                <div class="form-group">
                  <button v-if="recipe.status === workListStatus.OPEN" class="btn btn-outline-primary" @click="closeModal">Cancel</button>
                  <button v-if="recipe.status === workListStatus.OPEN" class="btn btn-primary ml-3" @click="updateBatchDetails">Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from 'laravel-form-validation';

export default {
  name: 'WorkListEditModal',
  props: {
    showModal: {
      type: Boolean,
    },
    recipeData: {
      type: Object,
    },
    employees: {
      type: Array,
    },
    workListStatus: {
      type: Object,
    },
  },
  data() {
    return {
      recipe: {
        recipe_id: this.recipeData.id,
        batch_size: this.recipeData.batch_size,
        due_date: this.recipeData.due_date,
        employee_id: this.recipeData.employee_id,
        open_to_all_employees: this.recipeData.open_to_all_employees,
        updated_at: this.recipeData.updated_at,
        notes: this.recipeData.notes
      },
      openToAllEmployeeRadio : this.recipeData.open_to_all_employees === 1 ? 'YES' : 'NO',
      form: new Form,
      batchSizeErrors: [],
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    updateBatchDetails() {
      this.batchSizeErrors = [];
      let hasErrors = false;

      if (this.recipe.work_list_batches.length === 1) {
        // Single batch rule
        const batch = this.recipe.work_list_batches[0];
        if (!batch.batch_size || batch.batch_size <= 0) {
          this.$set(this.batchSizeErrors, 0, 'Batch size must be greater than 0 for single work list batch.');
          hasErrors = true;
        }
      } else if (this.recipe.work_list_batches.length > 1) {
        // Multiple batch rule
        let atLeastOneGreaterThanZero = false;
        
        this.recipe.work_list_batches.forEach((item, index) => {
          if (item.batch_size == null || item.batch_size < 0) {
            this.$set(this.batchSizeErrors, index, 'Batch size must be 0 or greater.');
            hasErrors = true;
          } else {
            this.$set(this.batchSizeErrors, index, ''); // Clear error if batch size is valid
          }
          
          if (item.batch_size > 0) {
            atLeastOneGreaterThanZero = true;
          }
        });

        if (!atLeastOneGreaterThanZero) {
          this.batchSizeErrors.fill('At least one batch size must be greater than 0.');
          hasErrors = true;
        }
      }

      if (hasErrors) {
        return;
      }

      const payload = {
          recipe_id: this.recipe.recipe_id,
          due_date: this.recipe.due_date,
          notes: this.recipe.notes,
          open_to_all_employees: this.recipe.open_to_all_employees,
          employee_id: this.recipe.employee_id,
          recipe_yields: this.recipe.work_list_batches.map((item) => ({
            recipe_yield_id: item.recipe_yield.id,
            batch_size: item.batch_size,
          })),
        };

        this.form.put(route('admin.kitchen.work-list.update',this.recipe.id), payload)
          .then((response) => {
            this.$emit("update:recipeData", JSON.parse(JSON.stringify(this.recipe)));
            this.$toast.success('Batch Details Updated Successfully.')
            this.closeModal()
            this.$emit('batchDetailsUpdated')
          })
          .catch((error) => {
            console.log(error)
          })
        }
      },
  created() {
    this.recipe = JSON.parse(JSON.stringify(this.recipeData))
  },
  watch: {
    recipe: {
      handler: function (newValue) {
        (newValue.open_to_all_employees === true) ? this.recipe.employee_id = null : ''
      },
      deep: true,
    },
    openToAllEmployeeRadio: {
      handler: function (newValue) {
        this.recipe.open_to_all_employees = (newValue === 'YES')
      }
    }
  }
}
</script>

<style scoped>
.modal-body {
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
