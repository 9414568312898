<template>
  <div class="card mt-2 add-recipe" v-if="!recipeSaved">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h2 class="card-title">Create Recipe</h2>
      <div class="">
        <a class="btn btn-secondary" :href="route('admin.kitchen.recipe.index')">Back</a>
      </div>
    </div>
    <div class="card-body">
      <div class="container-fluid mt-4">
        <div class="row recipe-form">
          <div class="col-xl-9 col-lg-7 details-field">
            <form
              @submit.prevent="saveRecipe()"
              class="w-100"
              style="display: flex;"
              enctype="multipart/form-data"
            >
              <div class="col-12">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="name">Name of the Recipe</label>
                      <input
                        name="name"
                        type="text"
                        class="form-control"
                        v-model="recipe.name"
                        placeholder="Enter the name of the recipe"
                        v-invalid="form.$errors"
                      >
                      <field-error :bag="form.$errors" field="name"/>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="name">Category</label>
                      <multiselect
                        name="recipe_category_id"
                        v-model="recipe.category"
                        label="name"
                        track-by="id"
                        :options="categories"
                        :taggable="false"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder="Select a Category"
                      />
                      <field-error :bag="form.$errors" field="recipe_category_id"/>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="name">Description</label>
                      <textarea
                        name="description"
                        class="form-control"
                        placeholder="Enter the Description.."
                        v-model="recipe.description"
                        rows="10"
                        v-invalid="form.$errors"
                      />
                      <field-error :bag="form.$errors" field="description"/>
                    </div>
                  </div>
                </div>
                <hr class="mb-5" />
                <div class="row">
                  <div class="col-3">
                    <div class="form-group">
                      <label for="estimated_time" class="form-label">Enter Estimated time ( in minutes ) : </label>
                      <input
                        id="estimated_time"
                        name="estimated_time"
                        type="number"
                        class="form-control"
                        v-model="recipe.estimated_time"
                        v-invalid="form.$errors"
                      >
                      <field-error :bag="form.$errors" field="estimated_time"/>
                    </div>
                  </div>
                </div>
                <div>
                  <label for="" class="form-label">Select By : </label>
                  <label class="ml-2">
                    Name <input type="radio" value="name" v-model="catalogSelectBy">
                  </label>

                  <label class="ml-2">
                    SKU <input type="radio" value="sku" v-model="catalogSelectBy">
                  </label>
                  <div v-if="catalogSelectBy === 'sku'" class="d-flex sku-wrapper">
                    <input type="text"
                           class="form-control col-3"
                           placeholder="Search by SKU"
                           v-model="skuValue">
                    <button type="button"
                            @click="searchBySku"
                            class="btn btn-primary ml-2">
                      <i class="fa fa-search fa-fw"/>
                    </button>
                  </div>
                </div>
                <div class="row mt-3 recipe-yield-table">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Recipe Yield</label>
<!--                      <select-->
<!--                        name="catalog_id"-->
<!--                        class="form-control mb-2 mb-sm-0 mr-sm-2"-->
<!--                        v-model="recipe.catalog_id"-->
<!--                        v-invalid="form.$errors"-->
<!--                      >-->
<!--                        <option v-for="recipeYield of recipeYields" :value="recipeYield.id" :key="recipeYield.id">{{ recipeYield.name }}</option>-->
<!--                      </select>-->
                      <multiselect
                        id="catalog_id"
                        name="catalog_id"
                        label="nameWithCategory"
                        track-by="id"
                        v-model="recipe.catalog"
                        :options="recipeYieldsWithCategory"
                        @input="changeSelectBy"
                        :taggable="false"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder="Select Recipe Yield"
                        @remove="unselectRecipeYield"
                      />
                      <field-error :bag="form.$errors" field="catalog_id"/>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Unit Of Measure</label>
                      <select
                        name="variation_data_id"
                        class="form-control mb-2 mb-sm-0 mr-sm-2"
                        v-model="recipe.variation_data_id"
                        v-invalid="form.$errors"
                      >
                        <option v-if="!recipe.catalog_id"></option>
                        <option v-else v-for="(unit_of_measure) of recipeUnitOfMeasures" :value="unit_of_measure.id" :key="unit_of_measure.id">{{ unit_of_measure.value }}</option>
                      </select>
                      <field-error :bag="form.$errors" field="variation_data_id"/>
                      <div class="dynamic-label w-75 mt-2" v-if="recipe.variation_data_id">
                        <label>Sku: {{ recipeYieldSku }} </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <label>Qty</label>

                      <input

                        name="quantity"
                        type="text"
                        class="form-control"
                        v-model="recipe.quantity"
                        v-invalid="form.$errors"
                      >
                      <field-error :bag="form.$errors" field="quantity"/>
                      <div class="dynamic-label mt-2" v-if="recipe.variation_data_id && recipe.quantity">
                        <label>Price: {{'$ ' + recipeYieldPrice }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12">
                    <div class="form-group">
                      <input
                        type="submit"
                        class="btn btn-primary  submit-btn"
                        value="Save Recipe & Next"
                        :disabled="processing"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <input type="file"
                     @change="onFileChange"
                     ref="file"
                     name="image"
                     hidden>
            </form>
          </div>
          <div class="col-xl-3 col-lg-5 image-field">
            <div class="">
              <div class="form-group img-wrapper my-4 mx-auto" id="img-wrapper">
                <img width="386px;"
                     v-if="imagePreview"
                     :src="imagePreview"
                     alt="recipe_image" >
              </div>
              <label v-if="Object.keys(form.$errors.errors).length > 0 && form.$errors.errors.image !== undefined" class="text-danger invalid-error">{{ form.$errors.errors.image[0] }}</label>
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-block select-btn-custom"
                  @click="$refs.file.click()"
                >
                  Select an image of the Recipe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="addMultipleYields">
        <button class="btn btn-primary" @click="addMultipleYields()">&plus; Add Yield</button>
      </div>

      <div class="row align-items-center mb-3" v-if="recipe.recipe_yields.length > 0" id="multipleYields">
        <div class="col-12 my-5 ingredient-table-wrapper">
      <table class="table table-bordered ingredient-table">
        <thead>
            <tr>
              <th scope="col">Image</th>
              <th scope="col">Name</th>
              <th scope="col">UOM</th>
              <th scope="col">Qty</th>
              <th scope="col">Action</th>
            </tr>
        </thead>
        <draggable v-model="recipe.recipe_yields" tag="tbody">
        <tr v-for="(multipleYield, index) in recipe.recipe_yields" :key="index">
          <td><img :src="multipleYield.catalog.image" class="ingredient-image" alt=""></td>
          <td>{{ multipleYield.catalog.name }}</td>
          <td>{{ getUOM(multipleYield.catalog.id,multipleYield.unitOfMeasures).item_variation_data.name }}</td>
          <td>{{ multipleYield.quantity }}</td>
          <td>
            <div class="btn-group btn-group-sm">
              <a title="Edit" href="#multipleYields" class="btn btn-primary" @click="showEditYieldModal(index, multipleYield)"><i class="fas fa-edit"></i></a>
              <a title="Delete" href="#multipleYields" class="btn btn-danger ml-1" @click="deleteIngredient(multipleYield)"><i class="fas fa-trash"></i></a>
            </div>
          </td>
        </tr>
        </draggable>
      </table>
        </div>
      </div>
    </div>
    <recipe-catalog-image-modal v-if="showRecipeCatalogImageToggle" :show-modal="true" @changeImage="closeImageConfirmModal"/>

    <recipe-yield-edit-modal v-if="editYieldModalStatus"
                             :items="recipeYields"
                             :selected-yield-id="editRecipeYieldId"
                             :selected-yield="recipe.recipe_yields[editRecipeYieldId]"
                             @ingredientUpdated="updateIngredient"
                             @closeModal="closeModal" />
  </div>

  <recipe-step-create v-else
                      :items="recipeYields"
                      :step-timers="stepTimers"
                      :initial="recipe"
                      :create-single-step="false"
  />
</template>

<script>
import Form from 'laravel-form-validation';
import RecipeStepCreate from '@/js/KitchenHand/Components/Recipe/RecipeStepCreate';
import Multiselect from 'vue-multiselect';
import RecipeCatalogImageModal from '@/js/KitchenHand/Components/Recipe/RecipeCatalogImageModal.vue';
import draggable from 'vuedraggable';
import RecipeYieldEditModal from '@/js/KitchenHand/Components/Recipe/RecipeYieldEditModal.vue';

export default {
  name: 'RecipeCreate',
  components: {RecipeCatalogImageModal, RecipeStepCreate, Multiselect, draggable, RecipeYieldEditModal },
  props: {
    showModal: {
      type: Boolean,
    },
    categories: {
      type: Array,
    },

    recipeYields: {
      type: Array,
    },
    stepTimers: {
      type: Object,
    },
    items: {
      type: Array
    }
  },
  data() {
    return {
      recipe: {
        name: '',
        recipe_category_id: '',
        description: '',
        yield: '',
        unit_of_measure: '',
        quantity: '',
        image: '',
        id: null,
        catalog_id:null,
        variation_data_id: null,
        is_catalog_image: null,
        recipe_yields : []
      },
      editYieldModalStatus:false,
      editRecipeYieldId:null,
      editYieldSelected:{},
      imagePreview: null,
      recipeSaved: false,
      form: new Form(),
      processing: false,
      recipeYieldSku: '',
      catalogSelectBy: 'name',
      skuValue: '',
      showRecipeCatalogImageToggle: false,
    };
  },
  methods: {
    async deleteIngredient(multipleYield) {
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this yield?', {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      })

      if(!confirm) {
        return;
      }
      const index = this.recipe.recipe_yields.indexOf(multipleYield)
      this.recipe.recipe_yields.splice(index,1)
      this.$toast.success('Yield has been deleted')
    },
    updateIngredient(ingredient) {
      let ingredients = this.recipe.recipe_yields.map((ingredient) => ingredient.name);

      this.recipe.recipe_yields[this.editRecipeYieldId] = ingredient
      // this.stepItems = this.items.filter((ingredient) => ingredient.id !== parseInt(this.recipe.catalog_id))
      // let ingredientsToRemove = this.step.ingredients.map((ingredient) => ingredient.catalog_id)
      // this.stepItems = this.stepItems.filter((ingredient) => !ingredientsToRemove.includes(ingredient.id))
      this.editYieldModalStatus = false
    },
    showEditYieldModal(index, multiple){
      this.editYieldModalStatus = true;
      this.editRecipeYieldId = index
      this.editYieldSelected = multiple
    },
    closeModal(){
      this.editYieldModalStatus = false;
    },
    getUOM(catalogId, variationDataId) {
      return (this.items.find((item) => item.id === catalogId).variations_data).find((data) => data.id === variationDataId)
    },
    addMultipleYields(){
      if (this.recipe.quantity <= 0 || this.recipe.quantity > 9999.99) {
        this.$toast.error('Quantity must be greater than 0 and less than 9999.99')
        return;
      }
      const yieldGroup = {catalog: this.recipe.catalog, quantity: this.recipe.quantity ? parseFloat(this.recipe.quantity).toFixed(2) : this.recipe.quantity, unitOfMeasures: this.recipe.variation_data_id}
      if(!this.recipe.recipe_yields.includes(yieldGroup.catalog)){
        this.recipe.recipe_yields.push(yieldGroup);
      }
      this.recipe.quantity = null;
      this.recipe.variation_data_id = null;
      this.recipe.catalog  = null;
    },
    saveRecipe() {
      if(this.recipe.recipe_yields.length<=0){
        this.$toast.error('Please add a yield to proceed');
        return;
      }
      if (!this.recipeYieldSku &&
          (!!this.recipe.name && !!this.recipe.recipe_category_id && !!this.recipe.description
              && !!this.recipe.quantity)) {
        this.$toast.error('Can\'t save recipe yield with the undefined sku');
        return
      }
      this.processing = true;
      this.form.post(route('admin.kitchen.recipe.store'), this.recipe, {headers: {'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          if (response.status === 202) {
            this.$toast.error(response.message);
          } else {
            this.recipe = response.recipe;
            this.$toast.success('Recipe added successfully.');
            this.recipeSaved = true;
          }

        })
        .catch((error) => {
          console.log(error);
          // this.$toast.error('Something went wrong');
        })
        .finally(() => {
          this.processing = false;
        });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (file.size > 1000000) {
        alert('Image may not be greater than 1 Mb.')
      } else {
        this.recipe.image = file;
        this.imagePreview = URL.createObjectURL(file);
        this.recipe.is_catalog_image = false;
      }
    },
    searchBySku() {
      if (!this.skuValue.trim()) {
        this.$toast.error('Please enter some Sku Value !');
        return;
      }
      let selectedRecipeCatalog, selectedItemVariation;
      let skuFound = this.recipeYields.find((recipeYield) => {
        return Object.entries(recipeYield).find(([itemKey, itemValue]) => {
          if (itemKey === 'variations_data') {
            return Object.entries(itemValue).find(([variationDataKey, variationDataValue]) => {
              return Object.entries(variationDataValue).find(([itemVariationKey, itemVariationValue]) => {
                if (itemVariationKey === 'item_variation_data') {
                  if (itemVariationValue.hasOwnProperty('sku')) {
                    if (itemVariationValue.sku === this.skuValue) {
                      selectedRecipeCatalog = recipeYield
                      selectedItemVariation = variationDataValue.id
                      return true;
                    }
                  }
                }
              })
            })
          }
        })
      })
      if (!skuFound) {
        //   clearing the recipe yield if not found in sku
        this.$set(this.recipe, 'catalog', '')
        this.$set(this.recipe, 'catalog_id', null)
        this.$set(this.recipe, 'variation_data_id', null)
        this.$toast.error('No Sku found !');
      } else {
        this.$set(this.recipe, 'catalog', selectedRecipeCatalog)
        this.$set(this.recipe, 'catalog_id', selectedRecipeCatalog.id)
        this.$set(this.recipe, 'variation_data_id', selectedItemVariation)
        this.recipe.quantity = '';
      }
    },
    changeSelectBy() {
      this.catalogSelectBy = 'name'
      // for the image upload
      if (this.recipe.image) {
        this.showRecipeCatalogImageToggle = true;
      }
    },
    unselectRecipeYield() {
      this.recipe.variation_data_id = null
      this.recipe.catalog_id = null
      this.recipe.quantity = null
      this.imagePreview = null
    },
    closeImageConfirmModal(value) {
      this.showRecipeCatalogImageToggle = false;
      if (value) {
        this.recipe.image = null;
      }
    }
  },
  computed: {
    recipeYieldCategory: function (){
      return this.recipeYields.find((recipeYield) => recipeYield.id === this.recipe.catalog_id).category.category;
    },
    recipeYieldPrice: function (){
      let variationsData = this.recipeYields.find((item) => item.id === this.recipe.catalog_id)?.variations_data.find((uom) => uom.id === this.recipe.variation_data_id)
      let yieldPrice = (variationsData !== undefined && variationsData.item_variation_data.price_money) ? (variationsData.item_variation_data.price_money['amount']/100.00) * this.recipe.quantity : ''
      return yieldPrice ? yieldPrice.toFixed(2) : yieldPrice
    },
    recipeUnitOfMeasures: function (){
      let unitOfMeasures;
      if (this.recipe.catalog_id !== null && (this.recipe.variation_data_id !== '')) {
         unitOfMeasures = this.recipeYields.find((recipeYield) => recipeYield.id === parseInt(this.recipe.catalog_id))?.variations_data.map(function (unitOfMeasure) {
          return {
            id: unitOfMeasure.id,
            value: unitOfMeasure.item_variation_data.name + ' - ' + ((unitOfMeasure.item_variation_data.price_money) ? (unitOfMeasure.item_variation_data.price_money.amount/100.00) : 0),
            sku: unitOfMeasure.item_variation_data.sku,
          }
        });
      }

      // ((!this.recipeSaved) ? (this.recipe.variation_data_id = null) : '');
      // this.recipeYieldSku = unitOfMeasures[0].sku;
      return unitOfMeasures;
    },
    recipeYieldsWithCategory: function () {
      return this.recipeYields.map(recipeYield => {
          recipeYield.nameWithCategory = `${recipeYield.name} (${recipeYield.category.category})`
          return recipeYield
      })
    }
  },
  watch: {
    recipe: {
      handler: function (newValue) {
        if (this.recipe.variation_data_id !== null && this.recipe.variation_data_id !== '' && this.recipeUnitOfMeasures?.map((uom) => uom.id).includes(this.recipe.variation_data_id)) {
          this.recipeYieldSku = this.recipeUnitOfMeasures.find((uom) => uom.id === this.recipe.variation_data_id).sku
        }
        if ('catalog' in newValue) {
          if (newValue.catalog !== null) {
            this.recipe.catalog_id = newValue.catalog.id;
            if (!this.recipe.image) {
              this.imagePreview = newValue.catalog.image
              this.recipe.is_catalog_image = true;
            }
          }
        }
        if ('category' in newValue) {
          this.recipe.recipe_category_id = newValue.category.id;
        }
        if (newValue.catalog_id === null && newValue.variation_data_id === '') {
          this.recipe.variation_data_id = null;
        }
        if (newValue.catalog_id !== null && this.recipeYieldPrice === '') {
          this.recipe.variation_data_id = null;
          this.recipe.quantity = '';
        }
      },
      deep: true,
    },
    catalogSelectBy: {
      handler: function () {
        this.skuValue = ''
      }
    }
  }

};
</script>

<style scoped>
body {
  background-color: #e2e2e2;
}

#app {
  padding: 20px;
}

.add-recipe .card-header {
  padding: 1rem 2.1875rem;
}

.add-recipe .card-header::after {
  display: none;
}

.img-wrapper {
  border: 1px solid #ced4da;
  border-radius: 16px;
  position: relative;
  padding-top: 62.25%;
}

.img-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: inherit;
}

#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.dynamic-label{
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 25px;
  background-color: #6c757d;
}

.dynamic-label label {
  line-height: 1.2;
  margin-bottom: 0 !important;
  word-break: break-word;
}
.sku-wrapper input {
  min-width: 250px;
}

.add-ingredient-wrapper {
  background: #F8FBFF;
  padding: 2rem;
}
.step-title {
  padding-left: 7.5px;
}
.scale-checkbox label {
  margin-top: 3px;
}
.ingredient-image {
  border-radius: 50% !important;
  box-sizing: border-box;
  height: 60px;
  width: 60px;
}
.ingredient-table th {
  background: #F8F8F8;
  border: 1px solid #D9D9D9;
}
.ingredient-table tr th:nth-child(2) {
  width: 40%;
}
.ingredient-table tr th:nth-child(6), .ingredient-table tr th:nth-child(3) {
  width: 15%;
}
.ingredient-table tr th:nth-child(1), .ingredient-table tr th:nth-child(4), .ingredient-table tr th:nth-child(5) {
  width: 10%;
}
</style>
