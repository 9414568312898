<template>
  <div class="modal modal-center fade move-to-worklist-modal"
       :class="showModal ? 'show' : ''"
       :style="showModal ? 'padding-right: 14px; display: block;' : 'display: none'"
       id="moveToWorkListModal"
       ref="moveToWorkListModal"
       tabindex="-1"
       aria-labelledby="moveToWorkListModal"
       aria-hidden="true">
    <div class="modal-dialog modal-ml my-0">
      <div class="modal-content h-100 w-100">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h5 class="modal-title" id="exampleModalLabel">Add to Recipe Worklist</h5>
          <button
            type="button"
            class="close m-0 p-0 position-static"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true" class="text-dark">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="container m-3">
            <div class="row">
              <div class="col-11">
                <div class="recipe-data-wrapper">
                  <div class="border rounded p-2 mb-3" v-for="(recipeItem, index) in recipeData.recipe_yields" :key="index">
                    <h6>Recipe Yield : {{ recipeItem.catalog.name }}</h6>
                    <div class="form-group">
                      <input class="form-control"
                             type="number"
                             v-model="recipeItem.batch_size"
                             placeholder="Enter Batch Size"
                             :class="{ 'is-invalid' : batchSizeErrors[index]}"
                      >
                      <span class="text-danger">{{ batchSizeErrors[index] }}</span>
                    </div>
                    <h6>Batch Yield : {{ recipeItem.quantity }}</h6>
                    <h6>Total Yield : {{ recipeItem.quantity*recipeItem.batch_size || 0 }}</h6>
                  </div>
                </div>
                <div class="form-group">
                  <label for="" class="form-label">Select Due Date</label>
                  <input class="form-control"
                         type="date"
                         v-model="recipe.due_date"
                         :class="{ 'is-invalid' : form.$errors.has('due_date')}"
                  >
                  <span class="text-danger">{{ form.$errors.first('due_date') }}</span>
                </div>
                <div class="form-group">
                  <label for="" class="for-label">Enter Additional Notes</label>
                  <textarea class="form-control"
                            name="notes"
                            placeholder="Enter the Additional Notes"
                            v-model="recipe.notes"
                            rows="5"
                            :class="{ 'is-invalid' : form.$errors.has('notes')}"/>
                  <span class="text-danger">{{ form.$errors.first('notes') }}</span>
                </div>
                <div class="form-group">
                  <label for="" class="form-label ml-2">Open to all employees</label>
                  <label class="ml-2">
                    <input type="radio" value="YES" v-model="openToAllEmployeeRadio"> YES
                  </label>

                  <label class="ml-2">
                    <input type="radio" value="NO" v-model="openToAllEmployeeRadio"> NO
                  </label>
                </div>
                <div v-if="!recipe.open_to_all_employees" class="form-group">
                  <label for="" class="form-label">Assign to an Employee</label>
                  <select class="form-control"
                          v-model="recipe.employee_id"
                          :class="{ 'is-invalid' : form.$errors.has('employee_id')}"
                          :disabled="recipe.open_to_all_employees"
                  >
                    <option v-for="(employee, index) in employees" :key="index" :value="employee.id">{{ employee.name }}</option>
                  </select>
                  <span class="text-danger">{{ form.$errors.first('employee_id') }}</span>
                </div>
                <div class="form-group">
                  <button class="btn btn-outline-primary" @click="closeModal">Cancel</button>
                  <button class="btn btn-primary ml-3" @click="addToWorkList">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from 'laravel-form-validation';

export default {
  name: 'MoveToWorkListModal',
  props: {
    showModal: {
      type: Boolean,
    },
    initial: {
      type: Array,
    },
    recipeData: {
      type: Object,
    }
  },
  data() {
    return {
      employees: this.initial,
      recipe: {
        recipe_id: this.recipeData.id,
        batch_size: '',
        due_date: '',
        employee_id: '',
        open_to_all_employees: true,
        updated_at: this.recipeData.updated_at,
      },
      openToAllEmployeeRadio: 'YES',
      form: new Form,
      batchSizeErrors: [],
    }
  },
  methods: {
    addToWorkList() {
      this.batchSizeErrors = [];
      let hasErrors = false;

      if (this.recipeData.recipe_yields.length === 1) {
        // Validation for a single recipe yield
        const batch = this.recipeData.recipe_yields[0];
        if (batch.batch_size == null || batch.batch_size <= 0) {
          this.$set(
            this.batchSizeErrors,
            0,
            'Batch size is required and must be greater than 0 for a single item.'
          );
          hasErrors = true;
        }
      } else if (this.recipeData.recipe_yields.length > 1) {
        // Validation for multiple recipe yields
        let atLeastOneGreaterThanZero = false;

        this.recipeData.recipe_yields.forEach((recipeItem, index) => {
          if (recipeItem.batch_size == null) {
            // Empty batch size validation
            this.$set(this.batchSizeErrors, index, 'Batch size is required.');
            hasErrors = true;
          } else if (recipeItem.batch_size < 0) {
            // Negative batch size validation
            this.$set(
              this.batchSizeErrors,
              index,
              'Batch size must be 0 or greater.'
            );
            hasErrors = true;
          } else {
            // Clear the error if valid
            this.$set(this.batchSizeErrors, index, '');
          }

          // Check if any batch size is greater than 0
          if (recipeItem.batch_size > 0) {
            atLeastOneGreaterThanZero = true;
          }
        });

        // Ensure at least one batch size > 0
        if (!atLeastOneGreaterThanZero) {
          this.batchSizeErrors.fill(
            'At least one batch size must be greater than 0.'
          );
          hasErrors = true;
        }
      }

      // Stop submission if there are errors
      if (hasErrors) return;

      const payload = {
        recipe_id: this.recipe.recipe_id,
        due_date: this.recipe.due_date,
        notes: this.recipe.notes,
        open_to_all_employees: this.recipe.open_to_all_employees,
        employee_id: this.recipe.employee_id,
        recipe_yields: this.recipeData.recipe_yields.map((recipeItem) => ({
          recipe_yield_id: recipeItem.id,
          batch_size: recipeItem.batch_size ?? 0,  // Include the batch size from each item
        }))
      };

      // Send POST request
      this.form.post(route('admin.kitchen.work-list.store'), payload)
        .then(() => {
          this.$toast.success('Recipe Moved to worklist.')
          this.$emit('recipeMovedToWorkList')
          this.$emit('closeModal')
        })
        .catch((error) => {
          console.log(error)
        })
    },
    closeModal() {
      this.recipeData.recipe_yields.forEach((recipeItem) => {
        recipeItem.batch_size = '';
      });
      this.$emit('closeModal')
    }
  },
  watch: {
    recipe: {
      handler: function (newValue) {
        (newValue.open_to_all_employees === true) ? this.recipe.employee_id = null : ''
      },
      deep: true,
    },
    openToAllEmployeeRadio: {
      handler: function (newValue) {
        this.recipe.open_to_all_employees = (newValue === 'YES')
      }
    }
  }
}
</script>

<style scoped>
.modal-body {
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}
/* .recipe-data-wrapper {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
} */
</style>