<template>
  <div class="content-wrapper" v-if="Object.keys(response).length > 0">
    <recipe-preview v-if="viewDetails"
                    :initial="recipe"
                    :recipe-yields="recipeYields"
                    :step-timers="stepTimers"
                    :categories="categories"
                    :show-recipe-edit-button="editButtonIsVisible"
                    :show-add-step-button="addStepButtonIsVisible"
                    @closeRecipePreview="closeRecipePreview"
    />
    <recipe-edit
      v-else-if="showEditRecipeForm"
      :categories="categories"
      :recipe-yields="recipeYields"
      @showRecipePreview="showEditRecipeForm = false"
      @recipeUpdated="recipeUpdated"
      :initial="recipe"
      :items="recipeYields"
    />
    <section class="content" v-else>
      <div class="row">
        <div class="col-12">
          <recipe-create
            v-if="showCreateRecipeForm"
            :categories="categories"
            :items="recipeYields"
            :recipe-yields="recipeYields"
            :step-timers="stepTimers"
            @goBack="showRecipes"
          />
          <recipe-step-create
            v-else-if="showStepCreateForm"
            :step-timers="stepTimers"
            :items="recipeYields"
            :initial="recipe"
            :create-single-step="false"
          />
          <div class="card mt-2" v-else>
            <div class="card-header">
              <h2 class="card-title mb-1 ml-2">All Recipes</h2>
            </div>
            <div class="card-body">
              <div class="row align-items-start mb-3 input-header">
                <div class="col-xl-8">
                  <form id="search-form" class="form-inline mb-2" @submit.prevent="pageNavigated(1)">
                    <select class="form-control custom-select mb-2 mb-sm-0 mr-sm-2"
                            v-model="params.per_page">
                      <option disabled>Per page</option>
                      <option v-for="n in [15, 30, 50]" :value="n" :key="n">{{ n }}</option>
                    </select>
                    <b-dropdown variant="light" left class="mr-2">
                      <template #button-content>
                        <span>
                          All Categories
                        </span>
                      </template>
                      <div class="recipe-list">
                        <div class="form-group" v-if="categories.length === 0">
                          <label class="form-check-label ml-4">The list is empty.</label>
                        </div>
                        <div v-for="(category) in categories" :key="category.id" class="dropdown-item">
                          <div class="form-group">
                            <input type="checkbox" class="form-check-input"
                                   v-model="params.category_ids"
                                   :value="category.id"
                                   :id="`category-${category.id}`"
                            >

                            <label class="form-check-label"
                                   :for="`category-${category.id}`">{{ category.name }}</label>
                          </div>
                        </div>
                      </div>
                    </b-dropdown>
                    <select class="form-control mb-2 mb-sm-0 mr-sm-2" v-model="params.status" @change="fetch">
                      <option :value="2">All Recipes</option>
                      <option :value="1">Draft</option>
                    </select>
                    <input type="text"
                           class="form-control mb-2 mb-sm-0 mr-sm-2"
                           placeholder="Search"
                           v-model="params.search"
                           name="search">
                    <button type="submit"
                            class="btn btn-primary mb-0">
                      <i class="fa fa-search fa-fw"/>
                    </button>
                  </form>
                </div>
                <div class="col-xl-4 button-group">
                  <button class="btn btn-primary btn-small btn-secondary mr-2" @click="toggleModalState('showRecipeDownloadModal')" :disabled="selectedRecipes.length === 0">Download</button>
                  <button class="btn btn-primary btn-small" @click="createRecipe">+ Create New Recipe</button>
                </div>
              </div>
              <div class="row align-items-center mb-3">
                <div class="col-12 table-responsive">
                  <table id="table" class="table table-bordered table-striped table-hover recipe-index-table">
                    <thead>
                      <tr>
                        <th v-for="(column, key, index) in columns" :key="index">
                          <span v-if="column === 'Image'" class="mr-2">
                            <input type="checkbox" v-model="allSelected" name="selectAll"/>
                          </span>
                          <a href="#" @click="sort(key)"> {{ column }} </a>
                        </th>
                        <th>
                          <a href="#">Action</a>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="recipes.length > 0">
                      <tr v-for="(recipe, index) in recipes" :key="index">
                        <td>
                          <input
                            type="checkbox"
                            :value="recipe"
                            v-model="selectedRecipes"
                          >
                          <img :src="recipe.is_catalog_image ? recipe.catalog.image : recipe.image" class="ml-3" alt="">
                        </td>
                        <td>
                          {{ recipe.name }}
                        </td>
                        <td>
                          <p>{{ recipe.description }}</p>
                        </td>
                        <td>
                          {{ recipe.recipe_category.name }}
                        </td>
                        <td>
                          <p v-for="(recipe_yields, index) of recipe.recipe_yields" :key="index">
                            {{ recipe_yields.catalog.name }}
                          </p>
                        </td>
                        <td>
                          <p v-for="(recipe_yields, index) of recipe.recipe_yields" :key="index">
                            {{ getUnitOfMeasure(recipe_yields.catalog, recipe_yields.variation_data_id) }}
                          </p>
                        </td>
                        <td>
                          <p v-for="(recipe_yields, index) of recipe.recipe_yields" :key="index">
                            {{ recipe_yields.quantity }}
                          </p>
                        </td>
                        <td>
                          {{ recipe.in_work_list ? (recipe.is_completed_in_work_list ? 'No' : 'In Progress') : 'No' }}
                        </td>
                        <td>
                          {{ recipe.is_approved ? 'Yes' : 'No' }}
                        </td>
                        <td>
                          {{ formatDateTime(recipe.created_at) }}
                        </td>
                        <td>
                          {{ formatDateTime(recipe.updated_at) }}
                        </td>
                        <td>
                          <a data-toggle="dropdown" id="ellipsis-icon" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-h"/>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="tools">
<!--                            <span v-if="!recipe.in_work_list" class="dropdown-item" @click="editRecipe(recipe)">-->
<!--                              Edit Recipe-->
<!--                            </span>-->
                            <span v-if="params.status === 1" class="dropdown-item sync-button" @click="continueRecipe(recipe)">
                              Continue Recipe
                            </span>
                            <span v-if="params.status === 2" class="dropdown-item sync-button" @click="viewRecipeDetails(recipe)">
                              View Details - all edits can be accessed from this screen
                            </span>
                            <span v-if="params.status === 2" @click="moveToWorkList(recipe)" class="dropdown-item sync-button">
                              Add to Worklist
                            </span>
                            <span v-if="(!recipe.in_work_list || recipe.is_completed_in_work_list) && recipe.status === 2" class="dropdown-item" @click="editRecipeStatus(recipe)">
                              Edit Status
                            </span>
                            <span v-if="!recipe.in_work_list" class="dropdown-item" @click="deleteRecipe(recipe)">
                              Delete
                            </span>
                            <span v-if="recipe.in_work_list && recipe.is_completed_in_work_list" class="dropdown-item" @click="inactiveRecipe(recipe)">
                              Delete
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-else>
                      <td colspan="10">
                        <div class="text-center">
                          <strong>No Results Found</strong>
                        </div>
                      </td>
                    </tfoot>
                  </table>
                </div>
                <div class="mx-auto mt-2 text-center">
                      <pagination class="mb-2"
                                  @pagination-change-page="pageNavigated"
                                  :show-disabled="true"
                                  :data="response"
                                  :limit="2"/>
                      <button class="btn btn-primary" v-if="selectedRecipes.length > 0" @click="findRecipesThatAreInWorkList">Delete</button>
                    </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </section>


    <div class="modal modal-center fade select-batch-modal"
         :class="recipeDownloadModalIsVisible ? 'show' : ''"
         v-if="recipeDownloadModalIsVisible"
         :style=" 'padding-right: 14px; display: block;' "
         id="select-batch-modal"
         ref="selectBatchModal"
         tabindex="-1"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-sm h-30 my-0">
        <div class="modal-content w-100">
          <div class="modal-header d-flex justify-content-between align-items-center">
            <h5 class="modal-title" id="exampleModalLabel">Download Recipes</h5>
            <button
              type="button"
              class="close m-0 p-0 position-static cross"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal()"
            >
              <span aria-hidden="true" class="text-dark">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <div class="container p-3">
              <div class="row">
                <div class="col-12">
                  <ul class="list-group" v-if="recipeDownloadModalIsVisible">
                    <a class="btn btn-primary" @click="closeModal" :href="route('admin.kitchen.download-recipe', {'type': 'csv', 'recipes': this.selectedRecipes.map((recipe) => recipe.id)})">CSV File</a>
                    <a class="btn btn-primary mt-3" @click="closeModal" :href="route('admin.kitchen.download-recipe', {'type': 'pdf', 'recipes': this.selectedRecipes.map((recipe) => recipe.id)})">PDF</a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <move-to-work-list-modal v-if="showMoveToWorkListModal"
                             :recipe-data="recipe"
                             :show-modal="true"
                             :initial="employees"
                             @closeModal="closeModal"
                             @recipeMovedToWorkList="this.fetch"
    />
    <recipe-delete-modal v-if="showRecipeDeleteModal"
                         :show-modal="true"
                         :recipes="recipesThatAreInWorkList"
                         @modalClosed="closeModal"
                         @deleteRecipes="deleteMultipleRecipes"
    />
    <recipe-approve-modal v-if="showRecipeApproveModal"
                          :show-modal="showRecipeApproveModal"
                          :show-cancel-button="true"
                          @closeModal="closeModal"
                          :initial="recipe"/>

  </div>
</template>

<script>
import Form from 'laravel-form-validation';
import Pagination from 'laravel-vue-pagination';
import RecipeCreate from '@/js/KitchenHand/Components/Recipe/RecipeCreate';
import {scrollToTop} from '@/js/scrollToTop';
import RecipePreview from '@/js/KitchenHand/Components/Recipe/RecipePreview';
import dayjs from 'dayjs';
import RecipeStepCreate from '@/js/KitchenHand/Components/Recipe/RecipeStepCreate';
import MoveToWorkListModal from '@/js/KitchenHand/Components/WorkList/MoveToWorkListModal';
import RecipeDeleteModal from '@/js/KitchenHand/Components/Recipe/RecipeDeleteModal';
import RecipeEdit from '@/js/KitchenHand/Components/Recipe/RecipeEdit';
import RecipeApproveModal from '@/js/KitchenHand/Components/Recipe/RecipeApproveModal';

export default {
  name: 'RecipeIndex',
  components: {RecipeCreate, Pagination, RecipePreview, RecipeStepCreate, MoveToWorkListModal, RecipeDeleteModal, RecipeEdit, RecipeApproveModal},
  props: {
    authUser: {
      type: Object,
    },
    initial: {
      type: [Object, Array],
    },
    categories: {
      type: Array,
    },
    recipeYields: {
      type: Array,
    },
    employees: {
      type: Array,
    },
    stepTimers: {
      type: Object,
    }
  },
  created() {
    this.fetch()
  },
  data() {
    return {
      batch: 1,
      form: new Form(),
      recipeDownloadModalIsVisible: false,
      showCreateRecipeForm: false,
      sortKey: 'name',
      sortOrder: 'desc',
      columns: {
        'image': 'Image',
        'name': 'Name',
        'description': 'Description',
        'category': 'Category',
        'recipe_yield': 'Recipe Yield',
        'uom': 'UOM',
        'batch_yield': 'Batch Yield',
        'in_work_list': 'On WorkList',
        'is_approved': 'Is Approved',
        'created_at' : 'Created At',
        'updated_at' : 'Updated At'
      },
      params: {
        page: 1,
        search: null,
        per_page: 15,
        category_ids: [],
        status: 2,
      },
      processing: false,
      response: {},
      selectedRecipes: [],
      viewDetails: false,
      recipe: {},
      showEditRecipeForm: false,
      allSelected: false,
      showStepCreateForm: false,
      showMoveToWorkListModal: false,
      editButtonIsVisible: true,
      showRecipeDeleteModal: false,
      recipesThatAreInWorkList: [],
      deleteAllRecipes: true,
      showRecipeApproveModal: false,
      addStepButtonIsVisible:true,
    }
  },
  methods: {
    sort(sortKey) {
      if (sortKey === this.sortKey) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      }
      this.sortKey = sortKey;
    },
    toggleModalState(modal) {
      if(modal === 'showSelectBatchModal' || modal === 'closeSelectBatchModal') {
        this.selectBatchModalIsVisible = !this.selectBatchModalIsVisible;
      }
      if(modal === 'showRecipeDownloadModal' || modal === 'closeRecipeDownloadModal') {
        this.recipeDownloadModalIsVisible = !this.recipeDownloadModalIsVisible;
      }
    },
    pageNavigated(page = 1) {
      this.params.page = page;
      this.allSelected = false;
      this.fetch();
    },
    fetch() {
      this.processing = true;
      scrollToTop();
      this.$http.get(route('admin.kitchen.recipe.index'), {
        params: this.params,
      })
        .then((response) => {
          console.log(response.data);
          this.response = response.data;
        })
        .catch((error) => {
          this.$toast.error('Something went wrong');
        })
        .finally(() =>{
          this.processing = false;
        })
    },
    closeModal() {
      this.selectBatchModalIsVisible = false;
      this.recipeDownloadModalIsVisible = false;
      this.showMoveToWorkListModal = false;
      this.showRecipeDeleteModal = false;
      this.showRecipeApproveModal = false
    },
    createRecipe() {
      this.showCreateRecipeForm = true;
    },
    editRecipe(recipe) {
      this.recipe = recipe;
      this.showEditRecipeForm = true;
    },
    async deleteRecipe(recipe) {
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this recipe?', {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      })

      if(!confirm) {
        return;
      }

      this.form.delete(route('admin.kitchen.recipe.destroy', recipe.id))
        .then((response) => {
          this.$toast.success("Recipe has been deleted")
          setTimeout(function() { location.reload() }, 1000)
        })
        .catch(error => {
          console.error(error.message)
        })
    },
    async inactiveRecipe(recipe) {
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete selected recipes?', {
        title: 'Are you sure?',
        okVariant: 'danger',
        // okTitle: 'Delete',
        cancelTitle: 'Cancel',
      })

      if(!confirm) {
        return;
      }

      this.form.put(route('admin.kitchen.recipe.inactive', recipe.id))
        .then((response) => {
          this.$toast.success('Recipe has been removed')
          // setTimeout(function() { location.reload() }, 1000)
        })
        .catch(error => {
          console.error(error.message)
        })
    },
    findRecipesThatAreInWorkList() {
      this.recipesThatAreInWorkList = this.selectedRecipes.filter((recipe) => recipe.in_work_list === 1).map((recipe) => recipe.name)
      if (this.recipesThatAreInWorkList.length > 0) {
        this.showRecipeDeleteModal = true;
      } else {
        this.showConfirmation()
      }
    },
    async showConfirmation() {
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete selected recipes?', {
          title: 'Are you sure?',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
      })

      if (!confirm) {
        return;
      }
      this.deleteMultipleRecipes()
    },
    deleteMultipleRecipes() {
      this.selectedRecipes = this.selectedRecipes.map((recipe) => recipe.id);
      this.$http.post(route('admin.kitchen.recipe.deleteMultiple'), {'recipes' : this.selectedRecipes} )
        .then((response) => {
          if(response.status === 200) {
            this.$toast.success('Recipes Deleted Successfully.');
            setTimeout(function() { location.reload() }, 1000)
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error('Something went wrong')
        })
    },
    viewRecipeDetails(recipe) {
      this.editButtonIsVisible = this.addStepButtonIsVisible = recipe.in_work_list === 1 ?
          (!!recipe.is_completed_in_work_list) : true;
      this.recipe = recipe;
      this.viewDetails = true;
    },
    formatDateTime(dateTime) {
      return dateTime
    },
    showRecipes() {
      this.viewDetails = this.showCreateRecipeForm = this.showEditRecipeForm = false
    },
    closeRecipePreview() {
      this.fetch()
      this.viewDetails = false
    },
    continueRecipe(recipe) {
      this.recipe = recipe
      this.showStepCreateForm = true
    },
    moveToWorkList(recipe) {
      if (!recipe.is_approved) {
        this.$toast.error('Can\'t move Disapproved recipes to Worklist!');
        return;
      }
      this.recipe = recipe
      this.showMoveToWorkListModal = true
    },
    getUnitOfMeasure(catalog, variationDataId) {
      let variationsData = catalog.variations_data.find((uom) => uom.id === variationDataId)
      return (variationsData !== undefined) ? (variationsData.item_variation_data.name ?? variationsData.item_variation_data.name) : 'No UOM selected'
    },
    recipeUpdated() {
      this.viewDetails = true
    },
    editRecipeStatus(recipe) {
      this.showRecipeApproveModal = true
      this.recipe = recipe
    }
  },
  computed: {
    recipes: function () {
      return this.response.data;
    }
  },
  watch: {
    allSelected: function (newValue) {
      if(newValue === true) {
        this.selectedRecipes = this.recipes.map((recipe) => recipe);
      }else{
        if (newValue === false && this.selectedRecipes.length === this.recipes.length) {
          this.selectedRecipes = [];
        }
      }
    },
    selectedRecipes: function (recipes) {
      this.allSelected = recipes.length >= this.recipes.length
    }
  }
}
</script>

<style scoped>
.table-responsive {
  position: static;
}

.dropdown-item .form-group {
  flex-wrap: nowrap;
}
  span.dropdown-item{
    cursor: pointer;
  }
  .modal-button-group input {
    min-width: 115px;
  }
  img{
    border-radius: 50% !important;
    box-sizing: border-box;
    height: 60px;
    width: 60px;
  }
  #ellipsis-icon {
    cursor: pointer;
  }
  .recipe-index-table tr th {
    min-width: 150px;
  }

  .recipe-index-table tr td:nth-child(3) p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20ch;
  }

  .cross span {
    display: block;
    margin-top: -5px;
  }
</style>
