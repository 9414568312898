<template>
  <div class="card mt-2 edit-recipe">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h2 class="card-title">Edit Recipe</h2>
      <div class="ml-auto">
        <button class="btn btn-secondary" @click="showRecipePreview">Back</button>
      </div>
    </div>
    <div class="card-body">
      <div class="container-fluid mt-4">
        <div class="row recipe-form">
          <div class="col-xl-9 col-lg-7 details-field">
            <form
              @submit.prevent="updateRecipe()"
              class="w-100"
              style="display: flex;"
              enctype="multipart/form-data"
            >
              <div class="col-12">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="name">Name of the Recipe</label>
                      <input
                        name="name"
                        type="text"
                        class="form-control"
                        v-model="recipe.name"
                        placeholder="Enter the name of the recipe"
                        v-invalid="form.$errors"
                      >
                      <field-error :bag="form.$errors" field="name"/>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="name">Category</label>
                      <multiselect
                        name="recipe_category_id"
                        v-model="recipe.recipe_category"
                        label="name"
                        track-by="id"
                        :options="categories"
                        :taggable="false"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder="Select a Category"
                      />
                      <field-error :bag="form.$errors" field="recipe_category_id"/>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="name">Description</label>
                      <textarea
                        name="description"
                        class="form-control"
                        placeholder="Enter the Description.."
                        v-model="recipe.description"
                        rows="10"
                        v-invalid="form.$errors"
                      />
                      <field-error :bag="form.$errors" field="description"/>
                    </div>
                  </div>
                </div>
                <hr class="mb-5" />
                <div class="row">
                  <div class="col-3">
                    <div class="form-group">
                      <label for="estimated_time" class="form-label">Enter Estimated time ( in minutes ) : </label>
                      <input
                        id="estimated_time"
                        name="estimated_time"
                        type="number"
                        class="form-control"
                        v-model="recipe.estimated_time"
                        v-invalid="form.$errors"
                      >
                      <field-error :bag="form.$errors" field="estimated_time"/>
                    </div>
                  </div>
                </div>
                <div>
                  <label for="" class="form-label">Select By : </label>
                  <label class="ml-2">
                    Name <input type="radio" value="name" v-model="catalogSelectBy">
                  </label>

                  <label class="ml-2">
                    SKU <input type="radio" value="sku" v-model="catalogSelectBy">
                  </label>
                  <div v-if="catalogSelectBy === 'sku'" class="d-flex sku-wrapper">
                    <input type="text"
                           class="form-control col-3"
                           placeholder="Search by SKU"
                           v-model="skuValue">
                    <button type="button"
                            @click="searchBySku"
                            class="btn btn-primary ml-2">
                      <i class="fa fa-search fa-fw"/>
                    </button>
                  </div>
                </div>
                <div class="row mt-3 recipe-yield-table">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Recipe Yield</label>
<!--                      <select-->
<!--                        name="yield"-->
<!--                        class="form-control mb-2 mb-sm-0 mr-sm-2"-->
<!--                        v-model="recipe.catalog_id"-->
<!--                        v-invalid="form.$errors"-->
<!--                      >-->
<!--                        <option v-for="recipeYield of recipeYields" :value="recipeYield.id" :key="recipeYield.id">{{ recipeYield.name }}</option>-->
<!--                      </select>-->
                      <multiselect
                        id="catalog_id"
                        name="catalog_id"
                        label="nameWithCategory"
                        track-by="id"
                        v-model="recipe.catalog"
                        :options="recipeYieldsWithCategory"
                        @input="changeSelectBy"
                        :taggable="false"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder="Select Recipe Yield"
                        @remove="unselectRecipeYield"
                      />
                      <field-error :bag="form.$errors" field="catalog_id"/>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Unit Of Measure</label>
                      <select
                        name="variation_data_id"
                        class="form-control mb-2 mb-sm-0 mr-sm-2"
                        v-invalid="form.$errors"
                        v-model="recipe.variation_data_id"
                        @change="findSku(recipe.variation_data_id)"
                      >
                        <option v-for="(unit_of_measure) of recipeUnitOfMeasures" :value="unit_of_measure.id" :key="unit_of_measure.id">{{ unit_of_measure.value }}</option>
                      </select>
                      <field-error :bag="form.$errors" field="variation_data_id"/>
                      <div class="dynamic-label w-75 mt-2" v-if="recipe.variation_data_id && this.recipeUnitOfMeasures.find((uom) => uom.id === recipe.variation_data_id)">
                        <label>Sku: {{ recipeYieldSku }} </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <label>Qty</label>
                      <input
                        name="quantity"
                        type="text"
                        class="form-control"
                        v-model="recipe.quantity"
                        min="1"
                        v-invalid="form.$errors"
                      >
                      <field-error :bag="form.$errors" field="quantity"/>
                      <div class="dynamic-label mt-2" v-if="recipe.variation_data_id && recipe.quantity && this.recipeUnitOfMeasures.find((uom) => uom.id === recipe.variation_data_id)">
                        <label>Price: {{'$ ' + recipeYieldPrice }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12">
                    <div class="form-group">
                      <input
                        type="submit"
                        class="btn submit-btn btn-primary"
                        value="Update"
                        :disabled="processing"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <input type="file"
                     @change="onFileChange"
                     ref="file"
                     name="image"
                     hidden>
            </form>
          </div>
          <div class="col-xl-3 col-lg-5 image-field">
            <div class="">
              <div class="form-group img-wrapper my-4 mx-auto" id="img-wrapper">
                <img width="386px;"
                     v-if="imagePreview"
                     :src="imagePreview"
                     alt="recipe_image" >
              </div>
              <label v-if="Object.keys(form.$errors.errors).length > 0 && form.$errors.errors.image !== undefined" class="text-danger invalid-error">{{ form.$errors.errors.image[0] }}</label>
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-block select-btn-custom"
                  @click="$refs.file.click()"
                >
                  Select an image of the Recipe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="addMultipleYields">
        <button class="btn btn-primary" @click="addMultipleYields()">&plus; Add Yield</button>
      </div>

      <div class="row align-items-center mb-3" v-if="recipe.recipe_yields.length > 0" id="multipleYields">
        <div class="col-12 my-5 ingredient-table-wrapper">
          <table class="table table-bordered ingredient-table">
            <thead>
            <tr>
              <th scope="col">Image</th>
              <th scope="col">Name</th>
              <th scope="col">UOM</th>
              <th scope="col">Qty</th>
              <th scope="col">Action</th>
            </tr>
            </thead>
            <draggable v-model="recipe.recipe_yields" tag="tbody">
              <tr v-for="(multipleYield, index) in recipe.recipe_yields" :key="index">
                <td><img :src="multipleYield.catalog.image" class="ingredient-image" alt=""></td>
                <td>{{ multipleYield.catalog.name }}</td>
                <td>{{ getUOMByIngredient(multipleYield.catalog.id,multipleYield.variation_data_id).item_variation_data.name }}</td>
                <td>{{ multipleYield.quantity }}</td>
                <td>
                  <div class="btn-group btn-group-sm">
                    <a title="Edit" href="#multipleYields" class="btn btn-primary" @click="showEditYieldModal(index, multipleYield)"><i class="fas fa-edit"></i></a>
                    <a title="Delete" href="#multipleYields" class="btn btn-danger ml-1" @click="deleteYield(multipleYield, index)"><i class="fas fa-trash"></i></a>
                  </div>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
      </div>
    </div>
    <recipe-catalog-image-modal v-if="showRecipeCatalogImageToggle" :show-modal="true" @changeImage="closeImageConfirmModal"/>

    <recipe-yield-edit-modal v-if="editYieldModalStatus"
                             :items="recipeYields"
                             :selected-yield-id="editRecipeYieldId"
                             :selected-yield="editYieldSelected"
                             @ingredientUpdated="updateIngredient"
                             @closeModal="closeModal" />
  </div>

</template>

<script>
import Form from 'laravel-form-validation';
import Multiselect from 'vue-multiselect';
import RecipeCatalogImageModal from '@/js/KitchenHand/Components/Recipe/RecipeCatalogImageModal.vue';
import draggable from 'vuedraggable';
import RecipeYieldEditModal from '@/js/KitchenHand/Components/Recipe/RecipeYieldEditModal.vue';

export default {
  name: 'RecipeEdit',
  components: {draggable,RecipeCatalogImageModal, Multiselect, RecipeYieldEditModal},
  props: {
    showModal: {
      type: Boolean,
    },
    categories: {
      type: Array,
    },
    recipeYields: {
      type: Array,
    },
    initial: {
      type: Object,
    },
    items: {
      type: Array
    }
  },
  created() {
    this.recipe = JSON.parse(JSON.stringify(this.initial));
    this.imagePreview = this.recipe.is_catalog_image ? this.recipe.catalog.image : this.recipe.image;
    // this.recipeYieldSku = this.recipeUnitOfMeasures.find((item) => item.id === this.recipe.variation_data_id).sku
    // this.recipe.catalog = '';
    this.resetRecipeFields(); // Reset fields on first load
  },

  data() {
    return {
      recipe: {
        name: '',
        recipe_category_id: '',
        description: '',
        yield: '',
        unit_of_measure: '',
        quantity: 1,
        image: '',
      },
      editYieldModalStatus:false,
      editRecipeYieldId:null,
      editYieldSelected:{},
      imagePreview: null,
      recipeSaved: false,
      form: new Form(),
      processing: false,
      recipeYieldSku: '',
      catalogSelectBy: 'name',
      skuValue: '',
      showRecipeCatalogImageToggle: false,
    };
  },
  methods:{
    async deleteYield(multipleYield, index) {
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this yield?', {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      })

      if(!confirm) {
        return;
      }

      this.$http.delete(route('admin.kitchen.recipe-yields.destroy', multipleYield.id))
        .then((response) => {
          if (response) {
            this.recipe.recipe_yields.splice(index,1)
            this.$emit('recipeUpdated');
            this.$toast.success('Yield has been deleted')
          }
        })
        .catch((error) => {
          console.log(error)
        })

    },
    updateIngredient(ingredient) {
      const payload = {catalog_id: ingredient.catalog.id, quantity: ingredient.quantity, variation_data_id: ingredient.unitOfMeasures}

      this.$http.put(route('admin.kitchen.recipe-yields.update', this.recipe.recipe_yields[this.editRecipeYieldId].id), payload)
        .then((response) => {
          if (response) {
            this.recipe.recipe_yields[this.editRecipeYieldId] = response
            this.$toast.success('Yield has been added successfully')
            this.$emit('recipeUpdated');
          }
        })
        .catch((error) => {
          console.log(error)
        })
      this.editYieldModalStatus = false
    },
    showEditYieldModal(index, multiple){
      this.editYieldModalStatus = true;
      this.editRecipeYieldId = index
      this.editYieldSelected = {
        catalog: multiple.catalog,
        quantity: multiple.quantity,
        unitOfMeasures: multiple.variation_data_id
      }
    },
    closeModal(){
      this.editYieldModalStatus = false;
    },
    getUOMByIngredient(catalogId, variationDataId) {
      return (this.items.find((item) => item.id === catalogId).variations_data).find((data) => data.id === variationDataId)
    },
    resetRecipeFields() {
      this.recipe.catalog = null;
      this.recipe.quantity = null;
      this.recipe.variation_data_id = null;
    },
    addMultipleYields(){
      const yieldGroup = {catalog: this.recipe.catalog, quantity: this.recipe.quantity, variation_data_id: this.recipe.variation_data_id}

      const payload = {recipe_id:this.recipe.id ,catalog_id: this.recipe.catalog.id, quantity: this.recipe.quantity, variation_data_id: this.recipe.variation_data_id}

      this.$http.post(route('admin.kitchen.recipe-yields.store'), payload)
        .then((response) => {
          if (response) {
            if(!this.recipe.recipe_yields.includes(yieldGroup)){
              this.recipe.recipe_yields.push(yieldGroup);
            }
            this.$toast.success('Yield has been added successfully')
            this.$emit('recipeUpdated');
          }
        })
        .catch((error) => {
          console.log(error)
        })

      // Reset fields after adding
      this.resetRecipeFields();
    },
    updateRecipe() {
      this.processing = true;
      this.recipe.unit_of_measure = this.recipeUnitOfMeasures ? this.recipeUnitOfMeasures[0] : '';
      this.recipe.image === this.imagePreview ? delete this.recipe['image'] : ''
      // console.log(this.recipe.image)
      // this.recipe.isOldImage = this.recipe.is_catalog_image  ? false : !this.recipe.image
      // console.log(this.recipe.isOldImage)
      this.form.put(route('admin.kitchen.recipe.update',  this.recipe.id), this.recipe)
        .then((response) => {
          if (response.status === 202) {
            this.$toast.error(response.message);
          } else {
            this.$emit('recipeUpdated');
            this.$toast.success('Recipe updated successfully.');
          }
          this.recipeSaved = true;

        })
        .catch((error) => {
          console.log(error);
          // this.$toast.error('Something went wrong');
        })
        .finally(() => {
          this.processing = false;
        });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (file.size > 1000000) {
        alert('Image may not be greater than 1 Mb.')
      } else {
        this.recipe.image = file;
        this.imagePreview = URL.createObjectURL(file);
        this.recipe.is_catalog_image = false;
      }
    },
    showRecipePreview() {
      this.$emit('showRecipePreview')
    },
    findSku(variationDataId) {
      if (variationDataId !== null && variationDataId !== undefined) {
        this.recipe.variation_data_id = variationDataId
        this.recipeYieldSku = this.recipeUnitOfMeasures.find((item) => item.id === variationDataId).sku
      }
    },
    searchBySku() {
      if (!this.skuValue.trim()) {
        this.$toast.error('Please enter some Sku Value !');
        return;
      }
      let selectedRecipeCatalog, selectedItemVariation;
      let skuFound = this.recipeYieldsFiltered.find((recipeYield) => {
        return Object.entries(recipeYield).find(([itemKey, itemValue]) => {
          if (itemKey === 'variations_data') {
            return Object.entries(itemValue).find(([variationDataKey, variationDataValue]) => {
              return Object.entries(variationDataValue).find(([itemVariationKey, itemVariationValue]) => {
                if (itemVariationKey === 'item_variation_data') {
                  if (itemVariationValue.hasOwnProperty('sku')) {
                    if (itemVariationValue.sku === this.skuValue) {
                      selectedRecipeCatalog = recipeYield
                      selectedItemVariation = variationDataValue.id
                      return true;
                    }
                  }
                }
              })
            })
          }
        })
      })
      if (!skuFound) {
        //   clearing the recipe yield if not found in sku
        this.$set(this.recipe, 'catalog', '')
        this.$set(this.recipe, 'catalog_id', null)
        this.$set(this.recipe, 'variation_data_id', null)
        this.$toast.error('No Sku found !');
      } else {
        this.$set(this.recipe, 'catalog', selectedRecipeCatalog)
        this.$set(this.recipe, 'catalog_id', selectedRecipeCatalog.id)
        this.$set(this.recipe, 'variation_data_id', selectedItemVariation)
      }
    },
    changeSelectBy() {
      this.catalogSelectBy = 'name'
      // for the image upload
      if (this.recipe.image && this.recipe.catalog_id) {
        this.showRecipeCatalogImageToggle = true;
      }
    },
    unselectRecipeYield() {
      this.recipe.variation_data_id = null
      this.recipe.catalog_id = null
      this.recipe.quantity = null
      if (!this.recipe.image) {
        this.imagePreview = null
      }
      this.recipe.is_catalog_image = false
    },
    closeImageConfirmModal(value) {
      this.showRecipeCatalogImageToggle = false;
      if (value) {
        this.recipe.image = null;
      }
    }
  },
  computed: {
    recipeYieldCategory: function (){
      return this.recipeYieldsFiltered.find((recipeYield) => recipeYield.id === this.recipe.catalog_id).category.category;
    },
    recipeYieldPrice: function (){
      let variationsData = this.recipeYieldsFiltered.find((item) => item.id === this.recipe.catalog_id).variations_data.find((uom) => uom.id === this.recipe.variation_data_id)
      let yieldPrice = (variationsData !== undefined && variationsData.item_variation_data.price_money) ? (variationsData.item_variation_data.price_money['amount']/100.00) * this.recipe.quantity : 0
      return yieldPrice ? yieldPrice.toFixed(2) : yieldPrice
    },
    recipeUnitOfMeasures: function (){
      let unitOfMeasures = this.recipeYieldsFiltered.find((recipeYield) => recipeYield.id === this.recipe.catalog_id)?.variations_data.map(function (unitOfMeasure) {
        // for checking square item having parent child
        // if (/[,\-]/.test(unitOfMeasure.item_variation_data.name)){
        //   unitOfMeasure.item_variation_data.name = unitOfMeasure.item_variation_data.name.substring(
        //       unitOfMeasure.item_variation_data.name.indexOf('-') + 2
        //   )
        // }
        return {
          id: unitOfMeasure.id,
          value: unitOfMeasure.item_variation_data.name + ' - ' + ((unitOfMeasure.item_variation_data.price_money) ? (unitOfMeasure.item_variation_data.price_money.amount/100.00) : 0),
          sku: unitOfMeasure.item_variation_data.sku,
        }
      });

      if (this.recipe.catalog_id !== this.initial.catalog_id) {
        // this.recipe.variation_data_id = this.catalogSelectBy === 'sku' ? this.recipe.variation_data_id : '';
        this.recipe.quantity = '';
      }
      return unitOfMeasures;
    },
    recipeYieldsWithCategory: function () {
      return this.recipeYieldsFiltered.map(recipeYield => {
        recipeYield.nameWithCategory = `${recipeYield.name} (${recipeYield.category.category})`
        // Iterate over all recipe_yields and update the matching one
        this.recipe.recipe_yields.forEach(yieldItem => {
          if (recipeYield.id === yieldItem.catalog.id) {
            yieldItem.catalog.nameWithCategory = recipeYield.nameWithCategory;
          }
        });
        return recipeYield
      })
    },
    // method to filter the recipe yields data by removing the items already exist in the step ingredients
    recipeYieldsFiltered: function () {
      return this.recipeYields.filter(recipeYield => {
        let ingredientCatalogExist = false;
        this.recipe.steps.forEach((step) => {
          step.ingredients.forEach(ingredient => {
            if (ingredient.catalog_id ===  recipeYield.id) {
              ingredientCatalogExist = true;
            }
          })
        })
        return !ingredientCatalogExist;
      })
    }
  },
  watch: {
    recipe: {
      handler: function (newValue) {
        if (this.recipe.variation_data_id !== null && this.recipeUnitOfMeasures.map((uom) => uom.id).includes(this.recipe.variation_data_id)) {
          this.recipeYieldSku = this.recipeUnitOfMeasures.find((uom) => uom.id === this.recipe.variation_data_id).sku
        }
        if ('catalog' in newValue) {
          if (newValue.catalog !== null) {
            this.recipe.catalog_id = newValue.catalog.id;
            if (!this.recipe.image) {
              this.imagePreview = newValue.catalog.image
              this.recipe.is_catalog_image = true;
            }
          }
        }
        if ('recipe_category' in newValue) {
          if (newValue.recipe_category !== null) {
            this.recipe.recipe_category_id = newValue.recipe_category.id;
          }
        }
      },
      deep: true,
    },
    catalogSelectBy: {
      handler: function () {
        this.skuValue = ''
      }
    }
  }
}
</script>

<style>
body {
  background-color: #e2e2e2;
}

#app {
  padding: 20px;
}

.add-recipe .card-header {
  padding: 1rem 2.1875rem;
}

.add-recipe .card-header::after {
  display: none;
}

.img-wrapper {
  border: 1px solid #ced4da;
  border-radius: 16px;
  position: relative;
  padding-top: 62.25%;
}

.img-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: inherit;
}

#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.dynamic-label{
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 25px;
  background-color: #6c757d;
}

.dynamic-label label {
  line-height: 1.2;
  margin-bottom: 0;
  word-break: break-word;
}
.sku-wrapper input {
  min-width: 250px;
}
</style>
