<template>
  <div class="card mt-2">
    <div class="card-header">
      <h2 class="card-title mb-1 ml-2">History</h2>
    </div>
    <div class="card-body">
      <div class="row align-items-start mb-3">
        <div class="col-lg-12">
          <form id="search-form" class="form-inline mb-2" @submit.prevent="pageNavigated(1)">
            <select class="form-control custom-select mb-2 mb-sm-0 mr-sm-2"
                    v-model="params.per_page">
              <option disabled>Per page</option>
              <option v-for="n in [15, 30, 50]" :value="n" :key="n">{{ n }}</option>
            </select>
            <b-dropdown variant="light" left class="mr-2">
              <template #button-content>
                <span>
                  All Categories
                </span>
              </template>
              <div class="recipe-list">
                <div class="form-group" v-if="categories.length === 0">
                  <label class="form-check-label ml-4">The list is empty.</label>
                </div>
                <div v-for="(category) in categories" :key="category.id" class="dropdown-item">
                  <div class="form-group">
                    <input type="checkbox" class="form-check-input"
                           v-model="params.category_ids"
                           :value="category.id"
                           :id="`category-${category.id}`"
                    >

                    <label class="form-check-label"
                           :for="`category-${category.id}`">{{ category.name }}</label>
                  </div>
                </div>
              </div>
            </b-dropdown>
            <div class="recipe-list date-filter">
              <div>
                <label for="">From Date</label>
                <input type="date" name="from_date" v-model="params.from_date" class="mb-2">
              </div>
              <div class="ml-2">
                <label for="">To Date</label>
                <input type="date" name="to_date" v-model="params.to_date" class="mb-2">
              </div>
            </div>
            <div>
              <input type="text" class="form-control mb-2 mb-sm-0 mr-sm-2" placeholder="Search" v-model="params.search" name="search">
              <button type="submit" class="btn btn-primary mb-0">
                <i class="fa fa-search fa-fw"/>
              </button>
            </div>

          </form>
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <div class="col-12">
          <table id="table" class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th class="text-primary">
                  Image
                </th>
                <th class="text-primary">Name</th>
                <th class="text-primary">Category</th>
                <th class="text-primary">Recipe Yield</th>
                <th class="text-primary">UOM</th>
                <th class="text-primary">Updated at</th>
                <th class="text-primary">
                  <a href="#">Actions</a>
                </th>
              </tr>
            </thead>
            <tbody v-if="recipes.length > 0">
              <tr v-for="(recipe, index) in recipes" :key="index">
                <td>
                  <img :src="recipe.is_catalog_image ? recipe.catalog.image : recipe.image" class="ml-3" alt="">
                </td>
                <td>
                  {{ recipe.name }}
                </td>
                <td>
                  {{ recipe.recipe_category.name }}
                </td>
                <td>
                  <p v-for="(recipe_yields, index) of recipe.recipe_yields" :key="index">
                    {{ recipe_yields.catalog.name }}
                  </p>
                </td>
                <td>
                  <p v-for="(recipe_yields, index) of recipe.recipe_yields" :key="index">
                    {{ getUnitOfMeasure(recipe_yields.catalog, recipe_yields.variation_data_id) }}
                  </p>
                </td>
                <td>
                  {{ formatDateTime(recipe.updated_at) }}
                </td>
                <td>
                  <a data-toggle="dropdown" id="ellipsis-icon" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-ellipsis-h"/>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="tools">
                    <span class="dropdown-item sync-button" @click="viewHistory(recipe)">
                      View History
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot v-else>
              <td colspan="8">
                <div class="text-center">
                  <strong>No Results Found</strong>
                </div>
              </td>
            </tfoot>
          </table>
          <section class="text-center">
            <div class="table-responsive-sm">
              <pagination class="mb-0"
                          @pagination-change-page="pageNavigated"
                          :show-disabled="true"
                          :data="response"
                          :limit="2"/>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Pagination from 'laravel-vue-pagination';
import {scrollToTop} from '@/js/scrollToTop';
import dayjs from 'dayjs';

export default {
  name: 'RecipeHistory',
  components: {Pagination},
  props: {
    initial: {
      type: Object,
    },
    categories: {
      type: Array,
    },
  },
  created() {
    this.response = this.initial;
  },
  data() {
    return {
      params: {
        page: 1,
        search: null,
        per_page: 15,
        category_ids: [],
        from_date: '',
        to_date: '',
      },
      processing: false,
      response: {},
    }
  },
  methods: {
    fetch() {
      this.processing = true;
      scrollToTop();
      this.$http.get(route('admin.kitchen.history.indexRecipe'), {
        params: this.params,
      })
          .then((response) => {
            this.response = response.data;
          })
          .catch((error) => {
            this.$toast.error('Something went wrong');
          })
          .finally(() =>{
            this.processing = false;
          })
    },
    viewHistory(recipe) {
      this.$emit('show-history-index', recipe, true);
    },
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    formatDateTime(dateTime) {
      return dayjs(dateTime).format('YYYY-MM-DD')
    },
    getUnitOfMeasure(catalog, variationDataId) {
      let variationsData = catalog.variations_data.find((uom) => uom.id === variationDataId)
      // for checking square item having parent child
      if (/[,\-]/.test(variationsData.item_variation_data['name'])){
        variationsData.item_variation_data['name'] = variationsData.item_variation_data['name'].substring(
            variationsData.item_variation_data['name'].indexOf('-') + 2
        )
      }
      return variationsData.item_variation_data['name']
    },
  },
  computed: {
    recipes: function () {
      return this.response.data;
    }
  },
}
</script>

<style scoped>
td > a > i {
  cursor: pointer;
}

</style>
