<template>
  <div class="modal modal-center fade ingredient-edit-modal"
       :class="showModal ? 'show' : ''"
       :style="showModal ? 'display: block;' : 'display :none; '"
       id="ingredientEditModal"
       ref="ingredientEditModal"
       tabindex="-1"
       aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-ml h-30 my-0">
      <div class="modal-content h-100 w-100">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h5 class="modal-title" id="exampleModalLabel">Edit Yield</h5>
          <button
            type="button"
            class="close m-0 p-0 position-static"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="text-dark" @click="closeModal">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2">
          <div class="container">
            <div>
              <label for="" class="form-label">Select By : </label>
              <label class="ml-2">
                Name <input type="radio" value="name" v-model="catalogSelectBy">
              </label>

              <label class="ml-2">
                SKU <input type="radio" value="sku" v-model="catalogSelectBy">
              </label>
              <div v-if="catalogSelectBy === 'sku'" class="row ml-1 mb-2">
                <input type="text"
                       class="form-control col-3"
                       placeholder="Search by SKU"
                       v-model="skuValue">
                <button type="button"
                        @click="searchBySku"
                        class="btn btn-primary ml-2">
                  <i class="fa fa-search fa-fw"/>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Yield</label>
                  <multiselect
                    id="catalog_id"
                    name="catalog_id"
                    label="nameWithCategory"
                    track-by="id"
                    v-model="specificYieldSeleted.catalog"
                    @input="changeSelectBy"
                    :options="itemsWithCategory"
                    :multiple="false"
                    :taggable="false"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder="Select Yield"
                    @remove="unselectIngredient"
                  />
                </div>
                <div class="form-group">
                  <label>Unit of Measure</label>
                  <select class="form-control"
                          name="variation_data_id"
                          v-model="specificYieldSeleted.unitOfMeasures"
                          :class="{ 'is-invalid' : showUOMError}"
                          v-invalid="form.$errors"
                  >
                    <option v-for="(unit_of_measure, index) of recipeUnitOfMeasures"
                            :id="unit_of_measure.id"
                            :value="unit_of_measure.id"
                            :key="index">
                      {{ unit_of_measure.value }}
                    </option>
                  </select>
                  <span class="text-danger" v-if="showUOMError">Unit of measure field is required.</span>
                  <field-error :bag="form.$errors" field="variation_data_id"/>
                </div>
                <div class="form-group">
                  <label>Qty</label>
                  <input
                    name="quantity"
                    type="number"
                    class="form-control"
                    v-model="specificYieldSeleted.quantity"
                    min="1"
                    :class="{ 'is-invalid' : showQtyError}"
                    v-invalid="form.$errors"
                  >
                  <span class="text-danger" v-if="showQtyError">Quantity is required and it must be greater than 0 and less than 9999.99</span>
                  <field-error :bag="form.$errors" field="quantity"/>
                </div>
                <div class="form-group mt-2">
                  <button class="btn btn-primary" @click="updateIngredientJson(selectedYield)">Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import Form from 'laravel-form-validation';
import Multiselect from 'vue-multiselect';

export default {
  name: 'StepIngredientEditModal',
  components: { Multiselect },
  props: {
    items:{
      type: Array
    },
    selectedYieldId:{
      type: Number
    },
    selectedYield:{
      type: Object
    },
    initial: {
      type: Object,
    },
    ingredients: {
      type: Array
    },
    ingredientNumber: {
      type: Number,
    },
    stepIngredients: {
      type: Array,
    },
  },
  data() {
    return {
      step: {},
      ingredientId: this.ingredientNumber,
      ingredient: {
        step_id: '',
        name: '',
        catalog_id: null,
        unit_of_measure: '',
        variation_data_id: '',
        quantity: 1,
      },
      specificYieldSeleted: this.selectedYield,
      showModal: true,
      form: new Form,
      unitOfMeasures: [],
      item: [],
      showUOMError: false,
      showQtyError: false,
      processing: false,
      catalogSelectBy: 'name',
      skuValue: '',
      ingredientSku: ''
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    findUOM(ingredient) {
      this.unitOfMeasures = this.items.find((item) => item.name === ingredient).variations_data.map(function (unitOfMeasure) {
        return unitOfMeasure.item_variation_data.name ;
      });
    },
    /**
     *  Update the ingredient that are not stored in Database.
     */
    updateIngredientJson(ingredient) {
      this.showUOMError = this.showQtyError = false
      if (this.recipeUnitOfMeasures.find((uom) => uom.id === this.selectedYield.unitOfMeasures) === undefined) {
        this.showUOMError = true
      }
      if (isNaN(ingredient.quantity) || ingredient.quantity <= 0 || ingredient.quantity > 9999.99) {
        this.showQtyError = true
      }
      ingredient.quantity = parseFloat(ingredient.quantity).toFixed(2)
      if (!this.showUOMError && !this.showQtyError){
        this.$emit('ingredientUpdated', ingredient)
      }

      console.log('updated',ingredient);
    },
    searchBySku() {
      console.log('skuvalue',this.skuValue)
      if (!this.skuValue.trim()) {
        this.$toast.error('Please enter some Sku Value !');
        return;
      }
      let selectedRecipeCatalog, selectedItemVariation;
      let skuFound = this.items.find((recipeYield) => {
        return Object.entries(recipeYield).find(([itemKey, itemValue]) => {
          if (itemKey === 'variations_data') {
            return Object.entries(itemValue).find(([variationDataKey, variationDataValue]) => {
              return Object.entries(variationDataValue).find(([itemVariationKey, itemVariationValue]) => {
                if (itemVariationKey === 'item_variation_data') {
                  if (itemVariationValue.hasOwnProperty('sku')) {
                    if (itemVariationValue.sku === this.skuValue) {
                      selectedRecipeCatalog = recipeYield
                      selectedItemVariation = variationDataValue.id
                      return true;
                    }
                  }
                }
              })
            })
          }
        })
      })
      if (!skuFound) {
        //   clearing the recipe yield if not found in sku
        delete this.specificYieldSeleted.catalog
        // this.$set(this.step.ingredients[this.ingredientId], 'catalog_id', null)
        this.$set(this.specificYieldSeleted, 'variation_data_id', null)
        this.$set(this.specificYieldSeleted, 'quantity', null)
        this.$toast.error('No Sku found !');
      } else {
        this.$set(this.specificYieldSeleted, 'catalog', selectedRecipeCatalog)
        this.$set(this.specificYieldSeleted, 'catalog_id', selectedRecipeCatalog.id)
        this.$set(this.specificYieldSeleted, 'variation_data_id', selectedItemVariation)
      }
    },
    changeSelectBy() {
      this.catalogSelectBy = 'name'
    },
    findSku(variationDataId) {
      if (variationDataId !== null && variationDataId !== undefined) {
        return this.ingredientSku = this.recipeUnitOfMeasures.find((item) => item.id === variationDataId).sku
      }
    },
    unselectIngredient() {
      this.specificYieldSeleted.unitOfMeasures = null
      this.specificYieldSeleted.catalog.id = null
      this.specificYieldSeleted.quantity = null
    }
  },
  computed: {
    recipeUnitOfMeasures: function (){
      let unitOfMeasures = this.items.find((stepItem) => stepItem.id === this.selectedYield.catalog.id)?.variations_data.map(function (unitOfMeasure) {

        return {
          id: unitOfMeasure.id,
          value: unitOfMeasure.item_variation_data.name + ' - ' + ((unitOfMeasure.item_variation_data.price_money) ? unitOfMeasure.item_variation_data.price_money.amount/100.00 : 0),
          sku: unitOfMeasure.item_variation_data.sku,
        }
      });

      return unitOfMeasures;
    },
    itemsWithCategory: function () {
      return this.items.map(item => {
        item.nameWithCategory = `${item.name} (${item.category.category})`
        return item
      })
    }
  },
  watch: {
    catalogSelectBy: {
      handler: function () {
        this.skuValue = ''
      }
    }
  }

}
</script>

<style scoped>
.ingredient-edit-modal .modal-content {
  min-height: 465px;
}
.scale-checkbox label {
  margin-top: 3px;
}
</style>
